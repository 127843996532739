import { Button, Text } from '@talk360/components/atoms';
import { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  grantCallRequest(): void;
  sendSMS(): void;
  goBackModal(): void;
  isFlashCallPrimary: boolean;
  showGofastData: boolean;
  loading: boolean;
}

const FlashCallPermissionModal: FC<Props> = ({
  grantCallRequest,
  sendSMS,
  goBackModal,
  isFlashCallPrimary = false,
  loading = false,
  showGofastData = false,
}) => {
  const { t } = useTranslation();

  const requestGrant = () => {
    grantCallRequest();
  };

  const handleSecondaryClick = () => {
    if (showGofastData || isFlashCallPrimary) {
      sendSMS();
      return;
    }
    goBackModal();
  };

  return (
    <>
      <Text variant="header" className="text-center">
        <Trans i18nKey={`otp_page.grant_header_variant_${showGofastData ? 'two' : 'one'}`} />
      </Text>
      <Text className="text-center my-3 !text-tertiary">
        <Trans i18nKey={`otp_page.grant_subheader_variant_${showGofastData ? 'two' : 'one'}`} />
      </Text>
      <Button
        className="mt-4"
        onClick={requestGrant}
        disabled={loading}
        data-cy={showGofastData ? 'go-fast' : 'grant-access'}
      >
        <Trans
          i18nKey={`otp_page.grant_primary_button_variant_${showGofastData ? 'two' : 'one'}`}
        />
      </Button>
      <Button
        variant="secondary"
        className="!border-0 mt-3 shadow-none disabled:!bg-white disabled:!text-[#CECEE8]"
        onClick={handleSecondaryClick}
        disabled={loading}
        data-cy={showGofastData || isFlashCallPrimary ? 'text-instead' : 'go-back'}
      >
        {t(
          `otp_page.grant_secondary_button_variant_${
            showGofastData || isFlashCallPrimary ? 'two' : 'one'
          }`,
        )}
      </Button>
    </>
  );
};

export default FlashCallPermissionModal;
