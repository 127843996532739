import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import type { CountryProps, FORM_INPUTS } from '@talk360/types';
import { useTheme } from '@talk360/contexts/themeContext';

import { Icon, Text } from '../../atoms';
import { Sheet } from '../sheet';
import { CountryList } from './countryList';
import { SearchInput } from '../searchInput';

import countriesData from './countriesData.json';

interface CountryDropdownProps {
  isOpen: boolean;
  type: FORM_INPUTS.COUNTRY_INPUT | FORM_INPUTS.PHONE_NUMBER_INPUT;
  closeDropdown: () => void;
  setSelectedCountry: (country: CountryProps) => void;
}

const CountryPhoneDropdown = ({
  isOpen,
  type,
  closeDropdown,
  setSelectedCountry,
}: CountryDropdownProps) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const orderCountries = (allCountries: CountryProps[]) =>
    orderBy(allCountries, (country) => t(`countries.${country.code}`));

  const [searchValue, setSearchValue] = useState<string>('');
  const [countries] = useState<CountryProps[]>(orderCountries(countriesData));
  const [filteredCountries, setFilteredCountries] = useState<CountryProps[]>([...countries]);

  const findCountryByText = (text: string) =>
    orderCountries(
      countries.filter((country) =>
        t(`countries.${country.code}`).toLowerCase().includes(text.toLowerCase()),
      ),
    );
  const findCountryByDialingCode = (text: string) =>
    countries.filter(
      (country) =>
        country.dialing_code.startsWith(`+${text}`) || country.dialing_code.startsWith(text),
    );
  const findCountryByCountryCode = (text: string) =>
    countries.filter((country) => country.code.toLowerCase().includes(text.toLowerCase()));

  const filterCountryList = (searchValue: string) => {
    if (!searchValue) return setFilteredCountries(countries);

    const filters = [
      ...findCountryByDialingCode(searchValue),
      ...findCountryByCountryCode(searchValue),
      ...findCountryByText(searchValue),
    ];

    const result = uniqBy(filters, 'code');

    setFilteredCountries(result);
  };

  const resetDropdown = () => {
    setSearchValue('');
    setFilteredCountries(countries);
  };

  const handleSelectCountry = (country: CountryProps) => {
    setSelectedCountry(country);
    closeDropdown();
    resetDropdown();
  };

  const onCancel = () => {
    closeDropdown();
    resetDropdown();
  };

  return (
    <Fragment>
      <Sheet isOpen={isOpen} close={closeDropdown}>
        {isFacelift && (
          <div className="flex mb-2 justify-between">
            <Text variant="header" className="mt-4">
              {t('country_phone_input.search_country')}
            </Text>
            <button
              type="button"
              aria-label="back"
              className="ml-4 flex justify-center items-center w-7 h-7 bg-[#E0ECF1] rounded-full"
              onClick={onCancel}
              data-cy="cancel"
            >
              <Icon name="close" size={20} />
            </button>
          </div>
        )}
        <div className="flex items-center mb-4">
          <SearchInput
            isOpen={isOpen}
            currentValue={searchValue}
            placeholder={t('country_phone_input.search_placeholder')}
            dataCy="search"
            emittedSearchValue={(value) => {
              setSearchValue(value);
              filterCountryList(value);
            }}
          />
          {!isFacelift && (
            <button
              onClick={onCancel}
              type="button"
              className="ml-4 flex-shrink-0 text-[#007AFF] font-normal text-base w-[54px]"
              data-cy="cancel"
            >
              Cancel
            </button>
          )}
        </div>

        <CountryList
          countries={filteredCountries}
          type={type}
          onSelectCountry={handleSelectCountry}
        />
      </Sheet>
    </Fragment>
  );
};

export default CountryPhoneDropdown;
