import { TRIGGER_TYPES } from '@talk360/libs/device';
import { format } from 'libphonenumber-js';
import { isVersionGreaterOrEqual } from './semver';
import type { NavigateFunction } from 'react-router-dom';
import { session } from './session';
import type { SessionPayload } from '@talk360/types';
import { ROUTES } from '@talk360/types';

/**
 * @function combineClassName It's used to combine or merge class names togeter.
 *
 * @param {string} args[] An array of all the class names passed into the function. function can accept multiple class name strings that combined into a single string of classes.
 * @return {*} A single string of all combined class names.
 */
export const combineClassName = (...args: (string | undefined | null)[]): string => {
  return args.filter((item) => item).join(' ');
};

/**
 * @function filterList It's used to filter a value within an array of data.
 *
 * @param {string} searchValue This is the value the array data would be filtered against. Whichever datasets that meets the filter condition gets returned in the result.
 * @param {string[]} data The array data which the filter operation would be performed on. The `data` has to be an array of strings in all cases.
 * @return {*} {string[]} The array of datasets or values that meets the filter condition.
 */
export const filterList = (searchValue: string, data: string[]): string[] => {
  if (!searchValue) return data;

  return data.filter((option) => {
    if (typeof searchValue === 'string') {
      return option.toLowerCase().includes(searchValue.toLowerCase());
    }
  });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return format(phoneNumber, 'INTERNATIONAL');
};

export const prepareOTPCapture = () => {
  const { device } = window;
  if (device?.prepare_otp_capture) {
    device?.prepare_otp_capture();
    device?.trigger(TRIGGER_TYPES.SIGNUP_PIN_SENT); // OTP Sent
  }
};

export const handleTnC = (
  navigate: NavigateFunction,
  sendTrack: (event: string) => void,
  url: string,
) => {
  const { os, app_version } = session.getItem('session_payload') as SessionPayload;
  const isAndroid = os === 'android';
  const isSupportedAndroid = isVersionGreaterOrEqual(app_version, '8.10.0');

  sendTrack('terms_and_conditions');
  if (window.device?.openNativeBrowser && (!isAndroid || (isAndroid && isSupportedAndroid))) {
    window.device.openNativeBrowser(url);
    return;
  }
  navigate(ROUTES.TERMS_AND_CONDITION);
};
