import type { AllSteps } from '../routes';

export const FLASH_CALL_REQUEST_CALLBACK = 'flash_call_supported';
export const FLASH_CALL_VERIFICATION_CALLBACK = 'flash_call_verification';
export const VERIFY_USER_CALLBACK = 'user_verification_callback';

export interface FlashCallInitiatorProps {
  phoneNumber: string;
  isFlashCallPage?: boolean;
  isFlashCallPrimary?: boolean;
  current_step:
    | AllSteps.FIREBASE_OTP_STEP
    | AllSteps.SINCH_OTP_STEP
    | AllSteps.FLASH_CALL_STEP
    | AllSteps.FLASH_CALL_PERMISSION_STEP;
  showCallBtn: boolean;
  showTextBtn: boolean;
}

export interface FlashCallInitiatorViewProps extends FlashCallInitiatorProps {
  loading: boolean;
  showPermission: boolean;
  completed: boolean;
  showModal: boolean;
  showGoFastModal: boolean;
  handleCTAClick(): void;
  closeSheet(): void;
  handleCallRequest(): void;
  confirmedPhoneNumber(): void;
  handleCallback(): void;
  sendSMS(): void;
  goBackModal(): void;
}
