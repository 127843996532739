import type { TestFunction } from 'yup';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import type { CountryCode } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js';
import i18 from 'i18n';

const t = i18.t; // translation instance

/**
 * @function validatePhoneNumber A function to validaate phone numbers using the `isValidPhoneNumber` method from libphonenumber-js
 *
 * @param {(string | undefined)} phoneNumber The phone number to be checked if it's valid or not.
 * @param {*} context An object containing the country data for that step.
 * @return {*} boolean True or false whether the condition is met or not.
 */
const validatePhoneNumber: TestFunction<string | undefined> = (
  phoneNumber: string | undefined,
  context,
): boolean => {
  const country = context.parent.country;
  if (!country || !phoneNumber) return false;
  const [, dialing_code] = country.dialing_code.split('+');
  return isValidPhoneNumber(phoneNumber, {
    defaultCountry: country.code as CountryCode,
    defaultCallingCode: dialing_code,
  });
};

/**
 * @function _isValidPhoneNumber A yup schema to test valid phone numbers
 *
 * @return {*} void
 */
const _isValidPhoneNumber = () =>
  string().test('is-valid-phone-number', t('validations.phone_number'), validatePhoneNumber);

export const PhoneNumberValidationSchema = yupResolver(
  object().shape({
    phone_country: object().shape({
      country: object().shape({
        code: string().required(t('validations.country.code')),
        dialing_code: string().required(t('validations.country.dialing_code')),
      }),
      phone_number: _isValidPhoneNumber(),
    }),
  }),
);
