import { Api } from 'libs/api';
import type {
  NextStepPayload,
  NextStepResponse,
  PreviousStepPayload,
  RevertStepPayload,
  VerifyReferralCodePayload,
  VerifyReferralCodeResponse,
} from 'types';
import { Endpoints } from 'types';

/**
 * Http handler for getting the next onboarding step
 *
 * @param {NextStepPayload} data
 * @return {NextStepResponse}
 */
export const nextStep = (data: NextStepPayload) =>
  Api.post<NextStepPayload, NextStepResponse>(Endpoints.NEXT_STEP, data);

/**
 * Http handler for getting the previous onboarding step
 *
 * @param {PreviousStepPayload} data
 * @return {NextStepResponse}
 */
export const prevStep = (data: PreviousStepPayload) =>
  Api.post<PreviousStepPayload, NextStepResponse>(Endpoints.PREVIOUS_STEP, data);

/**
 * Http handler for getting the previous onboarding step
 *
 * @param {PreviousStepPayload} data
 * @return {NextStepResponse}
 */
export const revertStep = (data: RevertStepPayload) =>
  Api.post<RevertStepPayload, NextStepResponse>(Endpoints.REVERT_STEP, data);

/**
 * Http handler for verifying promo code
 *
 * @param {PreviousStepPayload} data
 * @return {NextStepResponse}
 */
export const verifyReferralCode = (data: VerifyReferralCodePayload) =>
  Api.post<VerifyReferralCodePayload, VerifyReferralCodeResponse>(
    Endpoints.CHECK_REFERRAL_CODE,
    data,
  );
