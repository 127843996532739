import { Button, Text, toast } from '@talk360/components/atoms';
import redirectResponse from '@talk360/libs/redirect-by-response';
import { revertStep } from '@talk360/services';
import type { RevertStepPayload } from '@talk360/types';
import { AllSteps, SESSION_ITEMS } from '@talk360/types';
import { formatPhoneNumber } from '@talk360/utils/functions';
import { session } from '@talk360/utils/session';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  phoneNumber: string;
  confirmed(): void;
  loading: boolean;
}

const FlashCallPhoneNumberVerificationModal: FC<Props> = ({
  phoneNumber,
  loading: isLoading,
  confirmed,
}) => {
  const [loading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const confirmPhoneNumber = () => {
    confirmed();
  };

  const reEnterPhoneNumber = async () => {
    setIsLoading(true);

    const payload: RevertStepPayload = {
      session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
      step_name: AllSteps.MSISDN_SUBMIT_STEP,
    };

    try {
      const response = await revertStep(payload);
      redirectResponse(response, navigate);
    } catch (error) {
      setIsLoading(false);
      toast(error as string);
    }
  };

  return (
    <>
      <Text variant="header" className="text-center">
        {t('otp_page.confirm_phone_header_variant_one')}
      </Text>
      <Text variant="header" className="text-center !font-normal my-3">
        {formatPhoneNumber(phoneNumber)}
      </Text>
      <Button
        className="mt-4"
        onClick={confirmPhoneNumber}
        disabled={loading || isLoading}
        data-cy="confirm-phone-number"
      >
        {t('otp_page.confirm_phone_primary_button_variant')}
      </Button>
      <Button
        variant="secondary"
        className="!border-0 mt-3 shadow-none disabled:!bg-white disabled:!text-[#CECEE8]"
        disabled={loading || isLoading}
        onClick={reEnterPhoneNumber}
        data-cy="re-enter-phone-number"
      >
        {t('otp_page.confirm_phone_secondary_button_variant')}
      </Button>
    </>
  );
};

export default FlashCallPhoneNumberVerificationModal;
