import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppRoutes from '@talk360/pages';
import { loadSegment } from '@talk360/libs/segment';
import { setupSentry } from '@talk360/libs/sentry';
import { handleDynamicLocaleSetup } from '@talk360/i18n';
import { CountdownProvider } from '@talk360/contexts/countdownContext';
import { useFont } from './hooks/useFont';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/styles.scss';
import { ScrollProvider } from './contexts/scrollContext';

const params = new URLSearchParams(window.location.search);

const App = () => {
  const [hasSetLanguage, setHasSetLanguage] = useState(false);

  useFont();

  const getParam = (key: string): string =>
    params.get(key) ||
    (sessionStorage?.queryParams && JSON.parse(sessionStorage?.queryParams)?.[key]);

  useEffect(() => {
    setupSentry();
    loadSegment();

    handleDynamicLocaleSetup(getParam('device_language')).then(() => {
      setHasSetLanguage(true);
    });
  }, []);

  if (!hasSetLanguage) return null;

  return (
    <div className="flex flex-col p-4 min-h-full">
      <ToastContainer
        toastClassName={() => ''}
        position="bottom-center"
        autoClose={false}
        draggable={false}
        closeButton={false}
      />
      <BrowserRouter>
        <ScrollProvider>
          <CountdownProvider>
            <AppRoutes />
          </CountdownProvider>
        </ScrollProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
