import { isVersionGreaterOrEqual } from '@talk360/utils/semver';

export const useForceUpdate = () => {
  const androidLimit = '8.3.0';
  const iOSLimit = '3.4.0';

  const isAndroidLimit = (appVersion: string) => !isVersionGreaterOrEqual(appVersion, androidLimit);

  const isIosLimit = (appVersion: string) => !isVersionGreaterOrEqual(appVersion, iOSLimit);

  const checkForceUpdate = (os: string, appVersion: string) => {
    if (os == 'android' && isAndroidLimit(appVersion)) {
      return true;
    }

    if (os == 'ios' && isIosLimit(appVersion)) {
      return true;
    }

    return false;
  };

  return { checkForceUpdate, isAndroidLimit, isIosLimit };
};
