import { session } from 'utils/session';
import type { NavigateFunction } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom';
import type { NextStepResponse, Steps, SessionResponse } from 'types';
import { MASTER_STEPS, SESSION_ITEMS } from 'types';

/**
 * @function redirectResponse A function to handle redirecting the user to a different step within the onboarding process.
 *
 * @param {(SessionResponse | NextStepResponse)} response The response from the request made before calling this function.
 * @param {NavigateFunction} navigate A function to handle updating the browser history.
 * @param {Record<string, string>} [params={}] Parameters to be passed into the broswer address bar if available.
 * @return {*} void
 */
const redirectResponse = (
  response: SessionResponse | NextStepResponse,
  navigate: NavigateFunction,
  params: Record<string, string> = {},
) => {
  session.setItem(response.next_step.type, {
    back_allowed: response.back_allowed,
    ...response[SESSION_ITEMS.NEXT_STEP],
  });

  if ((response as SessionResponse)[SESSION_ITEMS.SESSION_ID]) {
    session.setItem(
      SESSION_ITEMS.SESSION_ID,
      (response as SessionResponse)[SESSION_ITEMS.SESSION_ID],
    );
  }
  const nextPage = response.next_step.type as Steps;

  if (Object.keys(params).length) {
    navigate({ pathname: MASTER_STEPS[nextPage].ROUTE, search: `?${createSearchParams(params)}` });
    return;
  }
  navigate(MASTER_STEPS[nextPage].ROUTE);
};

export default redirectResponse;
