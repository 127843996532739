import _set from 'lodash/set';
import type { FieldValues } from 'react-hook-form';
import type { AllSteps, NextStepResponse, NextStepResponseB } from 'types';
import { createSchema } from 'utils/schemas/dynamic_schema';
import { session } from 'utils/session';

const useData = (currentStep: AllSteps) => {
  const currentStepSession = session.getItem<NextStepResponse & NextStepResponseB>(currentStep);
  const formData =
    currentStepSession?.next_step?.details.form_elements ||
    currentStepSession?.details?.form_elements ||
    [];

  const defaultValues: FieldValues = {};
  formData.forEach(({ value, name }) => _set(defaultValues, name, value));

  const relatedData =
    currentStepSession?.next_step?.details.related_data ||
    currentStepSession?.details?.related_data ||
    {};

  const persistStepData = (data: Record<string, unknown>): void => {
    const currentData = session.getItem<NextStepResponse & NextStepResponseB>(currentStep);

    session.setItem(currentStep, {
      ...currentData,
      stepData: data,
    });
  };

  const hasBack = currentStepSession?.back_allowed || false;
  const isSkippable =
    currentStepSession?.next_step?.details?.skippable ||
    currentStepSession?.details?.skippable ||
    false;

  const validationSchema = createSchema(formData);

  return {
    formData,
    data: currentStepSession,
    defaultValues,
    validationSchema,
    relatedData,
    hasBack,
    isSkippable,
    persistStepData,
  };
};

export default useData;
