import type Yup from 'yup';
import type { Message } from 'yup/lib/types';
import type Reference from 'yup/lib/Reference';
import type { PhoneCountryForm, CurrencyProps } from 'types';

export enum FORM_INPUTS {
  COUNTRY_INPUT = 'country',
  LABELLED_INPUT = 'labelled_input',
  PHONE_NUMBER_INPUT = 'phone',
  OTP_INPUT = 'otp_input',
  INPUT = 'input',
  CURRENCY_INPUT = 'currency',
}

export type inputModes =
  | 'numeric'
  | 'search'
  | 'text'
  | 'email'
  | 'tel'
  | 'url'
  | 'none'
  | 'decimal'
  | undefined;

export enum FORM_INPUT_TYPES {
  EMAIL = 'email',
  TEL = 'tel',
  NUMBER = 'number',
  TEXT = 'text',
}

export const InputMode = {
  [FORM_INPUT_TYPES.EMAIL]: FORM_INPUT_TYPES.EMAIL,
  [FORM_INPUT_TYPES.TEL]: FORM_INPUT_TYPES.TEL,
  [FORM_INPUT_TYPES.NUMBER]: 'numeric',
  [FORM_INPUT_TYPES.TEXT]: FORM_INPUT_TYPES.TEXT,
};

export type PhoneNumberFormFields = {
  phone_country: PhoneCountryForm;
};

export type CountryFormFields = Pick<PhoneCountryForm, 'country'>;

export type AgentCodeFormFields = {
  agent_code: string;
};

export type CurrencyFormFields = {
  user: {
    currency: string;
  };
};

export type FormStepFormFields = {
  user: {
    email: string;
    currency: string;
  };
  user_answers: {
    calling_country: string;
  };
  session_version: string;
};

type keys = Pick<typeof Yup, 'string'>;
export type MainValidators = keyof keys;

export type Arg = ((number | Reference<number>) &
  ((Message<Record<string, unknown>> & Message<{ regex: RegExp }>) | undefined)) &
  // eslint-disable-next-line @typescript-eslint/ban-types
  (Message<{}> | undefined);

export type Params = [Arg, Arg];

export interface Field {
  name: string;
  validations: { type: string; params: Params }[];
  input_type: FORM_INPUT_TYPES;
  type: FORM_INPUTS;
  validationType: MainValidators;
  value: string;
  options?: CurrencyProps[] | unknown[];
  selected?: string;
}
