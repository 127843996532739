import { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Text } from '@talk360/components/atoms';
import { AllSteps } from '@talk360/types';
import { Sheet } from '../sheet';
import FlashCallPermissionModal from '../flashCallPermisisonModal';
import FlashCallPhoneNumberVerificationModal from '../flashCallPhoneNumberVerificationModal';
import { LinkContainer } from '../emailLink';
import type { FlashCallInitiatorViewProps } from '@talk360/types/flash_call';

const FlashCallInitiatorView: FC<FlashCallInitiatorViewProps> = ({
  phoneNumber,
  current_step,
  isFlashCallPage = false,
  isFlashCallPrimary = false,
  loading,
  showCallBtn,
  showTextBtn,
  showPermission,
  showModal,
  showGoFastModal,
  completed,
  handleCTAClick,
  closeSheet,
  handleCallRequest,
  confirmedPhoneNumber,
  handleCallback,
  sendSMS,
  goBackModal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={classnames('mt-auto', {
          invisible:
            current_step === AllSteps.FLASH_CALL_PERMISSION_STEP ||
            ([AllSteps.SINCH_OTP_STEP, AllSteps.FIREBASE_OTP_STEP].includes(current_step) &&
              !completed) ||
            ([AllSteps.SINCH_OTP_STEP, AllSteps.FIREBASE_OTP_STEP].includes(current_step) &&
              showTextBtn &&
              !showCallBtn) ||
            !completed,
        })}
      >
        <Text className="mb-5">
          {isFlashCallPage && showTextBtn && t('otp_page.issue_sms_text')}
          {!isFlashCallPage && showCallBtn && t('otp_page.issue_call_text')}
          {!showTextBtn && !showCallBtn && (
            <Trans i18nKey="otp_page.resend_unavailable">
              text
              <LinkContainer phoneNumber={phoneNumber} className="text-primary font-[500]">
                support@talk360.com
              </LinkContainer>
              text
            </Trans>
          )}
        </Text>
        <Button
          onClick={handleCTAClick}
          disabled={loading}
          data-cy={
            (isFlashCallPage && showTextBtn && 'text-instead') ||
            (isFlashCallPage && showCallBtn && 'call-instead') ||
            (isFlashCallPage && showCallBtn && 'restart-verification') ||
            ''
          }
        >
          {isFlashCallPage && showTextBtn && t('otp_page.grant_secondary_button_variant_two')}
          {!isFlashCallPage && showCallBtn && t('otp_page.call_me_button')}
          {!showTextBtn && !showCallBtn && t('flash_call_page.verify_button')}
        </Button>
      </div>
      <Sheet isOpen={showModal} modal close={closeSheet}>
        {showPermission && (
          <FlashCallPermissionModal
            grantCallRequest={handleCallRequest}
            sendSMS={sendSMS}
            goBackModal={goBackModal}
            isFlashCallPrimary={isFlashCallPrimary}
            showGofastData={showGoFastModal}
            loading={loading}
          />
        )}
        {!showPermission && (
          <FlashCallPhoneNumberVerificationModal
            phoneNumber={phoneNumber}
            confirmed={confirmedPhoneNumber}
            loading={loading}
          />
        )}
      </Sheet>

      {/* Decoy */}
      <Button id="event-updater-decoy" className="hidden" onClick={handleCallback}>
        Callbacks Decoy
      </Button>
    </>
  );
};

export default FlashCallInitiatorView;
