import React, { useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { ROUTES, type SessionPayload } from '@talk360/types';
import { session } from '@talk360/utils/session';
import { useNativeGoBack, useSendSegmentEvents } from '@talk360/hooks';
import { startSession } from '@talk360/services';
import redirectResponse from '@talk360/libs/redirect-by-response';
import { toast } from '@talk360/components';
import { ReactComponent as Logo } from '@talk360/assets/svg/logo.svg';
import { useTheme } from '@talk360/contexts/themeContext';
import { APP_BUILD, APP_ENV } from '@talk360/constants';
import { useForceUpdate } from '@talk360/hooks/useForceUpdate';

const StartPage: React.FC = () => {
  session.clearSession();
  const navigate = useNavigate();
  const { sendPage, setAliasIdentity } = useSendSegmentEvents();
  useNativeGoBack(false, false, false, undefined);
  const { isFacelift } = useTheme();
  const { checkForceUpdate } = useForceUpdate();

  const [searchParams] = useSearchParams();

  const start = useCallback(async () => {
    const getInfo = window?.device?.get_device_info;
    const getParam = (key: string): string =>
      searchParams.get(key) ||
      (sessionStorage?.queryParams && JSON.parse(sessionStorage?.queryParams)?.[key]);

    if (getInfo) getInfo('storeQueryParams');

    const playground = getParam('playground');

    if (playground) {
      navigate({ pathname: ROUTES.PLAYGROUND });
      return;
    }

    const _capabilities = getParam('capabilities[]');
    const capabilities = _capabilities ? _capabilities.split(',') : [];

    setTimeout(async () => {
      const payload: SessionPayload = {
        tenant_id: 1,
        app_version: getParam('app_version'),
        device_signature: getParam('device_signature'),
        device_language: getParam('device_language'),
        os: getParam('os'),
        os_version: getParam('os_version'),
        country: getParam('country'),
        locale: getParam('locale'),
        manufacturer: getParam('manufacturer'),
        model: getParam('model'),
        timezone: getParam('timezone'),
        ip: getParam('ip'),
        features: getParam('features'),
        native_recaptcha_verification_possible: capabilities.includes('native_recaptcha'),
        flash_call_verification_possible: capabilities.includes('verification_flash_call'),
        anonymous_identifier: getParam('anonymous_identifier'),
      };

      session.setItem('session_payload', payload);

      if (checkForceUpdate(payload.os as string, payload.app_version))
        return navigate(ROUTES.FORCE_UPDATE);

      const firebaseOutSimulation = getParam('is_firebase_out');

      session.setItem('is_firebase_out', firebaseOutSimulation === 'true' ? true : false);

      sendPage('session_start', {
        onboarding_device_OS: payload.os_version,
        onboarding_device_platform: payload.os,
        onboarding_client_app_version: payload.app_version,
      });

      if (payload.anonymous_identifier) setAliasIdentity(payload.anonymous_identifier); // Set anonymous Identifier

      try {
        const startResponse = await startSession(payload);
        session.setItem('isCallFirst', false);

        if (window.device?.getConsentMode) window.device?.getConsentMode('setConsents');

        redirectResponse(startResponse, navigate);
      } catch (error) {
        captureException(error);
        toast(error as string);
      }
    }, 500);
  }, []);

  useEffect(() => {
    start();
    console.warn('App build is:', APP_BUILD);
  }, []);
  return (
    <div className="h-full w-full flex flex-col justify-center items-center my-auto">
      {isFacelift ? <img src="/logo.webp" width={125} /> : <Logo className="w-[125px]" />}
      {APP_ENV !== 'production' && <p className="text-center">{APP_BUILD}</p>}
    </div>
  );
};

export default StartPage;
