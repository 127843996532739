import { useEffect } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_ID } from '@talk360/constants';

interface Utils {
  sendPage(page: string, properties?: Properties): void;
  sendTrack(event: string): void;
  sendIdentity(userId: string): void;
  setAliasIdentity(anonymousId: string): void;
}

const writeKey = SEGMENT_ID as string;

export const useSendSegmentEvents = (step?: string): Utils => {
  const analytics = AnalyticsBrowser.load({ writeKey });
  const { track, page, identify, alias } = analytics;

  const sendTrack = (eventName: string) => track(eventName);

  const sendPage = (_page: string, properties?: Properties) => {
    page('Registration', _page, properties);
  };

  const sendIdentity = (userId: string) => identify(userId);

  const setAliasIdentity = (anonymousId: string) => {
    alias(anonymousId);
  };

  useEffect(() => {
    if (step) sendPage(step);
  }, []);

  return { sendPage, sendTrack, sendIdentity, setAliasIdentity };
};
