import { type FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from '@talk360/components/atoms';
import { session } from '@talk360/utils/session';
import type { FlashCallInitiatorProps } from '@talk360/types/flash_call';
import {
  FLASH_CALL_REQUEST_CALLBACK,
  FLASH_CALL_VERIFICATION_CALLBACK,
} from '@talk360/types/flash_call';
import { useCountdown } from '@talk360/contexts/countdownContext';
import { nextStep, revertStep } from '@talk360/services';
import type { RevertStepPayload } from '@talk360/types';
import { AllSteps } from '@talk360/types';
import { SESSION_ITEMS } from '@talk360/types';
import redirectResponse from '@talk360/libs/redirect-by-response';
import FlashCallInitiatorView from '../flashCallInitiationView';

const FlashCallInitiator: FC<FlashCallInitiatorProps & { majorPropsPresent: boolean }> = ({
  phoneNumber,
  current_step,
  isFlashCallPage = false,
  isFlashCallPrimary = false,
  showCallBtn,
  showTextBtn,
  majorPropsPresent = false,
}) => {
  if (!majorPropsPresent) {
    return null;
  }

  const navigate = useNavigate();

  const { completed } = useCountdown();
  const [showModal, setShowModal] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const [showGoFastModal, setShowGoFastModal] = useState(false);
  const [shortCircuitedFlashcall, setIsShortFlashCall] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    if (loading) return;
    setShowModal((state) => !state);
  };

  const handleCallback = () => {
    const result = session.getItem('playground') as Record<string, string>;
    setTimeout(async () => {
      if (result?.type === FLASH_CALL_REQUEST_CALLBACK && !result?.success) {
        setShowPermission(true);
        toggleModal();
        return;
      }
      try {
        if (result?.success && result?.type === FLASH_CALL_VERIFICATION_CALLBACK) {
          const flash_call_confirmed = result?.success;

          setLoading(true);
          const response = await nextStep({
            session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
            current_step,
            data: {
              flash_call_confirmed,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
          });
          redirectResponse(response, navigate);
        }
        if (result?.success && result?.type === FLASH_CALL_REQUEST_CALLBACK) {
          const data =
            shortCircuitedFlashcall || isFlashCallPrimary
              ? {}
              : {
                  retry_with: 'call',
                };

          setLoading(true);
          const response = await nextStep({
            session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
            current_step: shortCircuitedFlashcall
              ? AllSteps.FLASH_CALL_PERMISSION_STEP
              : current_step,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data: data as any,
          });
          if (response.next_step.type === AllSteps.FLASH_CALL_PERMISSION_STEP) {
            toggleCallRequest();
            setIsShortFlashCall(true);
            setLoading(false);
            return;
          }

          redirectResponse(response, navigate);
        }
      } catch (error) {
        setLoading(false);
        toast(error as string);
      }
    }, 100);
  };

  const handleCallRequest = () => {
    if (showGoFastModal) {
      setShowGoFastModal(false);
    }

    const { device } = window;
    device?.requestFlashCallPermissions(FLASH_CALL_REQUEST_CALLBACK);
    toggleModal();
  };

  const toggleCallRequest = () => {
    setShowPermission(true);
    setShowGoFastModal(true);
    toggleModal();
  };

  const retryWithSMS = (mode: 'sms' | 'call') => {
    return nextStep({
      session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
      current_step: shortCircuitedFlashcall ? AllSteps.FLASH_CALL_PERMISSION_STEP : current_step,
      data: {
        retry_with: mode,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    });
  };

  const sendSMS = async () => {
    try {
      setLoading(true);
      const response = await retryWithSMS('sms');
      redirectResponse(response, navigate);
    } catch (error) {
      setLoading(false);
      toast(error as string);
    }
  };

  const goBackModal = () => {
    toggleModal();
    setShowPermission(false);
  };

  const closeSheet = () => {
    if (current_step === AllSteps.FLASH_CALL_PERMISSION_STEP) return;
    toggleModal();
  };

  const askPermission = async () => {
    toggleModal();

    const isFirst = session.getItem('isCallFirst');

    if (
      [AllSteps.SINCH_OTP_STEP, AllSteps.FIREBASE_OTP_STEP].includes(current_step) &&
      !showTextBtn &&
      isFirst
    ) {
      setIsShortFlashCall(true);
      try {
        setLoading(true);
        const response = await retryWithSMS('call');

        if (response.next_step.type === AllSteps.FLASH_CALL_PERMISSION_STEP) {
          setLoading(false);
          toggleCallRequest();
          return;
        }
      } catch (error) {
        setLoading(false);
        toast(error as string);
      }
      return;
    }
    const { device } = window;
    device?.requestFlashCallPermissions(FLASH_CALL_REQUEST_CALLBACK);
  };

  const handleCTAClick = async () => {
    const payload: RevertStepPayload = {
      session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
      step_name: AllSteps.MSISDN_SUBMIT_STEP,
    };

    if (
      (isFlashCallPage && showTextBtn) ||
      ([AllSteps.SINCH_OTP_STEP, AllSteps.FIREBASE_OTP_STEP].includes(current_step) && showCallBtn)
    ) {
      toggleModal();
      return;
    }

    setLoading(true);
    try {
      const response = await revertStep(payload);

      if (response.next_step.type === AllSteps.FLASH_CALL_PERMISSION_STEP) {
        toggleCallRequest();
        return;
      }
      redirectResponse(response, navigate);
    } catch (error) {
      setLoading(false);
      toast(error as string);
    }
  };

  const confirmedPhoneNumber = () => {
    if (isFlashCallPage && showTextBtn) {
      sendSMS();
      return;
    }
    askPermission();
  };

  useEffect(() => {
    if (isFlashCallPrimary) {
      toggleCallRequest();
    }
  }, [isFlashCallPrimary]);

  return (
    <FlashCallInitiatorView
      phoneNumber={phoneNumber}
      current_step={current_step}
      isFlashCallPage={isFlashCallPage}
      isFlashCallPrimary={isFlashCallPrimary}
      loading={loading}
      showCallBtn={showCallBtn}
      showTextBtn={showTextBtn}
      showPermission={showPermission}
      showModal={showModal}
      showGoFastModal={showGoFastModal}
      completed={completed}
      handleCTAClick={handleCTAClick}
      closeSheet={closeSheet}
      handleCallRequest={handleCallRequest}
      confirmedPhoneNumber={confirmedPhoneNumber}
      handleCallback={handleCallback}
      sendSMS={sendSMS}
      goBackModal={goBackModal}
    />
  );
};

export default FlashCallInitiator;
