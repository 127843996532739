import type { InputProps } from '../../../types';
import { Input } from '../../atoms';

interface Props extends InputProps {
  label: string;
}

export const LabelledInput = ({ label, type, id, name, onChange }: Props) => {
  return (
    <div className="relative">
      <Input
        type={type}
        id={id}
        name={name}
        className="h-16 rounded-lg px-4 pt-4 w-full bg-white appearance-non focus:outline-none focus:ring-0 focus:border-blue-600 peer"
        placeholder=" "
        dataCy={name}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="absolute text-primary duration-300 transform -translate-y-4 scale-75 top-6 z-10 origin-[0] left-4 peer-focus:text-primary peer-placeholder-shown:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
      >
        {label}
      </label>
    </div>
  );
};
