import { init } from '@sentry/react';
import { SENTRY_DSN, APP_ENV } from '@talk360/constants';

export const setupSentry = () => {
  switch (APP_ENV) {
    case 'production':
    case 'staging':
      init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        environment: APP_ENV,
      });
      break;
    default:
      console.warn(`Sentry integration not added for ${APP_ENV} environment`);
  }
};
