import type { FC } from 'react';
import type { CurrencyProps } from 'types/molecules';
import { useTranslation } from 'react-i18next';

import { Empty, Text } from '../../atoms';

type CountryListProps = {
  currencies: CurrencyProps[];
  onSelectCurrency: (currency: CurrencyProps) => void;
};

export const CurrencyList: FC<CountryListProps> = ({ currencies, onSelectCurrency }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-y-auto rounded-[12px_12px_0px_0px] bg-white h-[calc(100%-56px)]">
      {currencies.length === 0 ? (
        <Empty />
      ) : (
        <ul className="list-none">
          {currencies.map((currency) => {
            return (
              <li
                key={`${currency.code}${currency.symbol}`}
                className="flex items-center px-4 py-5 cursor-pointer"
                onClick={() => onSelectCurrency(currency)}
                data-cy={currency.code}
              >
                <Text className="ml-6 text-black flex-grow">
                  {t(`currencies.${currency.code}`)} {currency.symbol && `(${currency.symbol})`}
                </Text>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
