import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { isVersionGreaterOrEqual } from '@talk360/utils/semver';

type ThemeContextProps = {
  isFacelift: boolean;
  checkDone: boolean;
  defaultTheme: boolean;
  setFacelift(facelift: boolean): void;
  setCurrentTheme(facelift: boolean): void;
};

export const handleFaceliftCheck = (): boolean => {
  const searchParams = new URL(window.location.href).searchParams;
  const os = searchParams.get('os') || sessionStorage.getItem('os');
  const appVersion = searchParams.get('app_version') || sessionStorage.getItem('appVersion');

  let isFaceLift = false;

  if (os == 'android' && appVersion) {
    isFaceLift = isVersionGreaterOrEqual(appVersion, '8.0.0');
  }

  if (os == 'ios' && appVersion) {
    isFaceLift = isVersionGreaterOrEqual(appVersion, '3.0.0');
  }

  sessionStorage.setItem('appVersion', appVersion as string);
  sessionStorage.setItem('os', os as string);

  return isFaceLift;
};

export const ThemeContext = createContext<ThemeContextProps | null>(null);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFacelift, setFacelift] = useState<boolean>(false);
  const [checkDone, setCheckDone] = useState<boolean>(false);
  const defaultTheme = useRef(handleFaceliftCheck());

  const setCurrentTheme = (is_facelift: boolean) => {
    const body = document.getElementsByTagName('body')[0].classList;

    if (!is_facelift && body.contains('facelift')) {
      body.remove('facelift');
      return;
    }
    if (is_facelift && !body.contains('facelift')) {
      body.add('facelift');
      return;
    }
  };

  useEffect(() => {
    const facelift = handleFaceliftCheck();

    setFacelift(facelift);
    setCurrentTheme(facelift);
    setCheckDone(true);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        isFacelift,
        checkDone,
        defaultTheme: defaultTheme.current,
        setCurrentTheme,
        setFacelift,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
