/**
 * @method stringifyData It's used to convert data to a stringified state.
 *
 * @template T
 * @param {T} data Data can be of any data-type whether object, arrays, maps e.t.c
 * @return {*} {string} a stringified format of the data
 */
export const stringifyData = <T>(data: T): string => JSON.stringify(data);

/**
 * @method parseData It's used to parse a stringified data back to its original state.
 *
 * @template T
 * @param {string} data Stringified data to be converted back to it's original state
 * @return {*} The converted data
 */
export const parseData = (data: string) => JSON.parse(data);

export const session = {
  /**
   * @method setItem It's used to add items to the SessionStorage object.
   *
   * @template T
   * @param {string} key - Key used to save the item in the storage
   * @param {T} data - The item to be stored in Session
   * @return {*} {void}
   */
  setItem: <T>(key: string, data: T): void => sessionStorage.setItem(key, stringifyData<T>(data)),
  /**
   * @method getItem It's used to get items from the SessionStorage object.
   *
   * @template T
   * @param {string} key - Key used to save the item
   * @return {*} {(T | null)}
   */
  getItem: <T>(key: string): T | null => {
    const data = sessionStorage.getItem(key);
    return data ? parseData(data) : data;
  },
  /**
   * @method removeItem It's used to remove items from the SessionStorage object.
   *
   * @template T
   * @param {string} key - Key used to save the item
   * @return {*} {void}
   */
  removeItem: (key: string): void => sessionStorage.removeItem(key),
  /**
   * @method clearSession It's used to clear all items in the SessionStorage object.
   *
   * @template T
   * @return {*} {void}
   */
  clearSession: (): void => sessionStorage.clear(),
};
