import classNames from 'classnames';

interface BackdropProps {
  onClose: () => void;
  className?: string;
}

export const Backdrop = ({ className, onClose }: BackdropProps) => (
  <div
    className={classNames('bg-black/50 fixed left-0 right-0 bottom-0 top-0', className)}
    onClick={onClose}
    data-cy="modal-backdrop"
  />
);
