import { useEffect, useRef } from 'react';
import type { ChangeEvent, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon, Input } from '../../atoms';
import { useTheme } from '@talk360/contexts/themeContext';

interface Props {
  isOpen?: boolean;
  currentValue: string;
  placeholder: string;
  emittedSearchValue(value: string): void;
  dataCy: string;
}

export const SearchInput = ({
  isOpen,
  currentValue,
  placeholder,
  emittedSearchValue,
  dataCy,
}: Props) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      searchInputRef?.current?.focus();
      setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }), 50);
    }
  }, [isOpen]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    emittedSearchValue(value);
  };

  return (
    <div
      className={classNames(
        'flex items-center flex-grow bg-white',
        { 'border border-secondary/50 rounded-full': isFacelift },
        { 'rounded-lg': !isFacelift },
      )}
    >
      <span className="h-10 w-10 flex items-center justify-center rounded-lg">
        <Icon name="search" />
      </span>
      <Input
        type="text"
        value={currentValue}
        className={classNames('flex-grow bg-transparent pr-[10px] py-[10px] focus:outline-none', {
          'h-10': !isFacelift,
          'h-12': isFacelift,
        })}
        placeholder={placeholder || t('search_input.placeholder')}
        onChange={handleChange}
        dataCy={dataCy}
        ref={searchInputRef as ForwardedRef<HTMLInputElement>}
      />
    </div>
  );
};
