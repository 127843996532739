import i18n from 'i18n';
export interface ServerError {
  code: string;
  message: string;
}

/**
 * @function getError
 * @param {string} code this is the error code gotten from the API response
 * @return {*}  {string} The error message
 */
export const getError = (code: string): string => {
  const translation = `server_errors.${code}`;
  return i18n.exists(translation) ? translation : 'server_errors.112';
};
