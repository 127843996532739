import React from 'react';
import classNames from 'classnames';

import { useTheme } from '@talk360/contexts/themeContext';
import { ReactComponent as ArrowLeftPreFacelift } from '@talk360/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightPreFacelift } from '@talk360/assets/icons/arrow-right.svg';
import { ReactComponent as CheckCirclePreFacelift } from '@talk360/assets/icons/check-circle.svg';
import { ReactComponent as ChevronDownPreFacelift } from '@talk360/assets/icons/chevron-down.svg';
import { ReactComponent as SearchPreFacelift } from '@talk360/assets/icons/search.svg';

import { ReactComponent as CloseFacelift } from '@talk360/assets/icons/close.svg';
import { ReactComponent as ArrowLeftFacelift } from '@talk360/assets/icons/arrow-left-facelift.svg';
import { ReactComponent as ChevronDownFacelift } from '@talk360/assets/icons/chevron-down-facelift.svg';
import { ReactComponent as SearchFacelift } from '@talk360/assets/icons/search-facelift.svg';
import { ReactComponent as HeartInHand } from '@talk360/assets/icons/heart-in-hand.svg';
import { ReactComponent as CircleChecked } from '@talk360/assets/icons/circle-tick.svg';

const iconMap = {
  'arrow-left': [ArrowLeftPreFacelift, ArrowLeftFacelift],
  'arrow-right': [ArrowRightPreFacelift],
  'check-circle': [CheckCirclePreFacelift],
  'chevron-down': [ChevronDownPreFacelift, ChevronDownFacelift],
  search: [SearchPreFacelift, SearchFacelift],
  close: [CloseFacelift],
  'heart-in-hand': [HeartInHand],
  success: [CircleChecked],
};

type IconName = keyof typeof iconMap;

interface Props {
  name: IconName;
  color?: string;
  size?: number;
  className?: string;
}

export const Icon: React.FC<Props> = ({ name, color = '#000', size = 24, className }) => {
  const { isFacelift } = useTheme();

  const IconComponent = iconMap[name][isFacelift ? 1 : 0] ?? iconMap[name][0];

  return (
    <IconComponent
      width={size}
      height={size}
      className={classNames(`color-[${color}]`, className)}
    />
  );
};
