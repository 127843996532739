import { Fragment, useRef, useEffect, useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Text, Icon } from '../../atoms';
import { filterList } from 'utils/functions';
import { Dropdown } from '../dropdown';
import { useTheme } from '@talk360/contexts/themeContext';

interface Props {
  onChange(value: string): void;
  value: string;
  name: string;
  placeholder: string;
  options: string[];
  errors: FieldErrors;
}

export const Country = ({ onChange, value, name, errors, options, placeholder }: Props) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const _options = useRef<string[]>(options.map((item) => t(`countries.${item}`).toString()));

  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  useEffect(() => {
    if (value) {
      const defaultOption = options.find((option) => option === value);
      defaultOption && onChange(defaultOption);
    }
  }, [value]);

  const toggleDropdown = () => setIsOpen((open) => !open);

  const filterBySearch = (search: string) => {
    const _result = filterList(search, _options.current);
    const result = options.filter((item) => _result.includes(t(`countries.${item}`)));
    setFilteredOptions(result);
  };

  const onSelectOption = (option: string) => {
    onChange(option);
    toggleDropdown();
  };

  const onSelectCountry = (option: string) => {
    onSelectOption(option);
  };

  return (
    <Fragment>
      {isFacelift && <Text className="!text-sm mb-2">{t('country_page.country')}</Text>}
      <div
        className={classNames(
          'border rounded-lg',
          { 'border-primary': !isFacelift },
          { 'border-secondary/50': isFacelift },
          {
            'border-red-400 overflow-hidden bg-white': errors[name],
          },
        )}
      >
        <button
          onClick={toggleDropdown}
          type="button"
          aria-label="toggle-country-dropdown"
          className={classNames(
            'flex justify-between items-center rounded-lg bg-white border-[2px_solid_rgba(0,0,0,0.08)] w-full',
            {
              'pl-3 h-12': isFacelift,
              'h-16 pl-4': !isFacelift,
            },
          )}
          data-cy="toggle-country-dropdown"
        >
          <div className="flex items-center">
            <div className={`mr-3 flag:${value || 'DFLT'}`} />
            <Text color="black">
              {value ? t(`countries.${value}`) : t('country_phone_input.country_placeholder')}
            </Text>
          </div>

          <Icon className="mr-3" name="chevron-down" />
        </button>
      </div>

      <Dropdown
        isOpen={isOpen}
        options={options}
        placeholder={placeholder}
        closeDropdown={toggleDropdown}
        searchValue={filterBySearch}
      >
        <>
          {(filteredOptions.length ? filteredOptions : options).map((option) => {
            return (
              <li
                key={option}
                className="flex items-center px-4 py-5 cursor-pointer"
                onClick={() => onSelectCountry(option)}
                data-cy={option}
              >
                <div className={`flex items-center w-[50px]`}>
                  <div className={`mr-4 flag:${option}`} />
                </div>

                <Text className="flex-grow" color="black">
                  {t(`countries.${option}`)}
                </Text>
              </li>
            );
          })}
        </>
      </Dropdown>
    </Fragment>
  );
};
