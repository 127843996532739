import type { FunctionComponent, ButtonHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
interface ProvidedProps {
  variant?: 'primary' | 'secondary';
}

type Props = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren<ProvidedProps>;

export const Button: FunctionComponent<Props> = ({
  variant = 'primary',
  className,
  disabled,
  children,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        'btn',
        { 'btn-primary': variant === 'primary' },
        { 'btn-secondary': variant === 'secondary' },
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
