import type { FC, ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, createRef, Fragment } from 'react';
import { useState } from 'react';
import classNames from 'classnames';

import { useTheme } from '@talk360/contexts/themeContext';
import { Input, Text } from '../../atoms';
import { useTranslation } from 'react-i18next';

interface Props {
  length?: number;
  loading?: boolean;
  value?: string[];
  emittedOTP(otp: string[]): void;
}

let currentOTPIndex = 0;

export const OTPInput: FC<Props> = ({ length = 6, emittedOTP, value, loading = false }) => {
  const [otps, setOtp] = useState<string[]>(new Array(length).fill(''));
  const [activeOtpIndex, setActiveOtpindex] = useState<number>(0);

  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const inputRef = createRef<HTMLInputElement>();

  const getOnlyLengthCharacters = (text = '') => text.substring(0, length);

  const handlePaste = (text: string) => {
    const formattedOTP = Array.from({ length }, (_, k) => getOnlyLengthCharacters(text)[k] || '');
    setActiveOtpindex(text.length < length ? text.length : text.length - 1);
    currentOTPIndex = text.length - 1;
    emittedOTP(formattedOTP);
    setOtp(formattedOTP);
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value.length > 2) return handlePaste(value);
    const newOtp: string[] = [...otps];
    newOtp[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOtpindex(currentOTPIndex - 1);
    else setActiveOtpindex(currentOTPIndex + 1);

    setOtp(newOtp);
    emittedOTP(newOtp);
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>, index: number) => {
    currentOTPIndex = index;
    if (key === 'Backspace') setActiveOtpindex(currentOTPIndex - 1);
  };

  const handleFocus = (index: number) => {
    setActiveOtpindex(index);
    currentOTPIndex = index;
  };

  useEffect(() => {
    if (value?.length) setOtp(value);
  }, [value]);

  useEffect(() => {
    if (!loading) {
      inputRef.current?.focus();
    }
  }, [activeOtpIndex, loading]);

  return (
    <Fragment>
      {isFacelift && <Text className="!text-sm mb-2">{t('otp_page.otp_digits')}</Text>}

      <div className={classNames('flex items-center justify-between', { 'mt-4': !isFacelift })}>
        {otps.map((_, index) => {
          return (
            <Input
              key={index}
              ref={index === activeOtpIndex ? inputRef : null}
              type="number"
              id={`otp${index}`}
              value={otps[index]}
              maxLength={1}
              inputMode="numeric"
              className={classNames(
                'bg-white text-center text-4xl leading-[46px] rounded-lg max-w-[52px] h-[55px] outline-none no-spinner transition',
                { 'border-0': !isFacelift },
                { 'text-header !m-0': isFacelift },
                { 'border border-secondary': isFacelift && activeOtpIndex === index },
                { 'border border-secondary/50': isFacelift && activeOtpIndex !== index },
              )}
              onChange={handleChange}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
              onFocus={() => handleFocus(index)}
              disabled={loading}
              dataCy={`otp-${index}`}
            />
          );
        })}
      </div>
    </Fragment>
  );
};
