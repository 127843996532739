import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _axios from 'axios';
import axiosRetry from 'axios-retry';

import { getError } from '../../utils/error-handler';
import type { ServerError } from '../../utils/error-handler';
import { API_BASE_URL } from '@talk360/constants';

const axios: AxiosInstance = _axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * @function retryDelay Generate random number of seconds for delays before re-trying a request
 *
 * @param {number} [numberOfRetries=0] The number of retries to be made for a request
 * @return {*} number The generated number of delay in seconds
 */
const retryDelay = (numberOfRetries = 0): number => Math.pow(2, numberOfRetries) * 1000;

const retryConfig = {
  retries: 3,
  retryDelay,
  retryCondition: axiosRetry.isRetryableError,
};

axiosRetry(axios, retryConfig);

/**
 * Handle success response from a HTTP request
 *
 * @template T
 * @param {AxiosResponse<T>} res The Object response returned from a request. Includes different properties like the `headers`, `status`, `config` e.t.c
 * @return {*}  {T} The data extracted from a response
 */
const handleApiSuccess = <T>(res: AxiosResponse<T>): T => {
  return res.data;
};

/**
 * Handle error response from HTTP request
 *
 * @param {AxiosError} error A network related error returned from the server
 * @return void
 */
const handleApiError = (error: AxiosError): void => {
  let errorMessage = '';

  if (!error.status) {
    errorMessage = error.message;
  }

  if (_axios.isCancel(error)) {
    return; // Fail silently
  }

  if (error.response?.data) {
    errorMessage = (error.response.data as { error: string }).error;
  }

  if ((error.response?.data as ServerError)?.code) {
    errorMessage = getError((error.response?.data as ServerError).code || 'generic_errors.general');
  }

  throw errorMessage;
};

export const Api = {
  /**
   * @method post Axios method used to make a POST request.
   *
   * @template T, U
   * @param {string} endpoint server url to make request to.
   * @param {T} data - The data to be sent to the server
   * @param {AxiosRequestConfig} config Object containing axios request configurations. In here, configurations like the headers, params e.t.c can be set
   * @return {*} {Promise} Promised function to handle the request for success or errors
   */
  post: <T, U>(endpoint: string, data: T, config?: AxiosRequestConfig): Promise<U> =>
    axios.post(endpoint, data, config).then(handleApiSuccess).catch(handleApiError),
  /**
   * @method get Axios method used to make a GET request.
   *
   * @template T
   * @param {string} endpoint server url to make request to.
   * @param {AxiosRequestConfig} config Object containing axios request configurations. In here, configurations like the headers, params e.t.c can be set
   * @return {*} {Promise} Promised function to handle the request for success or errors
   */
  get: <T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> =>
    axios.get(endpoint, config).then(handleApiSuccess).catch(handleApiError),
};
