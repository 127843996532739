import { useState, type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import type { PhoneCountryForm, PhoneNumberFormFields } from 'types';
import { AllSteps } from 'types';
import { FORM_INPUTS } from 'types';
import { FormInput } from '../../molecules';
import { Text } from '../../atoms';
import { PhoneNumberValidationSchema } from 'utils/schemas/phone_number';
import useData from 'libs/prepare-validation-schema';
import { useSendSegmentEvents } from 'hooks';
import parsePhonenumber from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js';
import has from 'lodash/has';

// TODO: Fix the upward reference of this component conflicting with the atomic design pricples of the app
import { NavigationBar } from '../navigationBar';
import { FormFrame } from '../../templates/formFrame';
import FlashCallInitiator from '@talk360/components/molecules/flashCallInitiation';
import { useSearchParams } from 'react-router-dom';

interface Props {
  sendOTP(phoneNumber: string): void;
  isLoading: boolean;
  container: string;
  currentStep: AllSteps;
}

export const PhoneNumber: FC<Props> = ({ isLoading, sendOTP, currentStep }) => {
  const { hasBack, persistStepData, relatedData } = useData(currentStep);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [isFlashCallPrimary, setFlashCallPrimary] = useState(false);

  useSendSegmentEvents('insert_phone');

  const defaultValues = {
    phone_country: {
      country: {
        name: '',
        code: (relatedData as unknown as PhoneCountryForm).country?.code || 'NL',
        dialing_code: (relatedData as unknown as PhoneCountryForm).country?.dialing_code || '+31',
      },
      phone_number: (relatedData as unknown as PhoneCountryForm).phone_number || '',
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { isValid },
  } = useForm<PhoneNumberFormFields>({
    defaultValues,
    resolver: PhoneNumberValidationSchema,
  });

  const formValues: PhoneCountryForm = watch('phone_country');

  const handleSendOtp = ({
    phone_country: { phone_number, country },
  }: PhoneNumberFormFields): void => {
    persistStepData({ phone_number, country });

    const phoneNumber = `${country.dialing_code}${phone_number}`;
    const phone = parsePhonenumber(phoneNumber, country as unknown as CountryCode)?.number;

    sendOTP(phone as unknown as string);
  };

  useEffect(() => {
    const isFlashPermission = !!searchParams.get('flash_permission');
    setFlashCallPrimary(isFlashPermission);
  }, [searchParams]);

  return (
    <>
      <NavigationBar isNavigating={isLoading} hasBack={hasBack} />

      <Text variant="header" fontWeight="bold">
        {t('msisdn_page.main_header')}
      </Text>

      <Text variant="subheader">{t('msisdn_page.sub_header')}</Text>

      <FormFrame
        primaryButtonText={t('msisdn_page.button_latest')}
        onSubmit={handleSubmit(handleSendOtp)}
        isLoading={isLoading}
        primaryCyValue="send-verification-btn"
        isPrimaryButtonDisabled={!isValid || isLoading}
      >
        <FormInput
          name="phone_country"
          type={FORM_INPUTS.PHONE_NUMBER_INPUT}
          setValue={setValue}
          register={register}
          trigger={trigger}
          loading={isLoading}
          formValues={formValues}
        />
      </FormFrame>
      {isFlashCallPrimary && (
        <FlashCallInitiator
          phoneNumber={`+${relatedData.msisdn}` as string}
          current_step={AllSteps.FLASH_CALL_PERMISSION_STEP}
          isFlashCallPrimary={isFlashCallPrimary}
          showCallBtn={!!relatedData?.show_call_button}
          showTextBtn={!!relatedData?.show_sms_button}
          majorPropsPresent={
            has(relatedData, 'show_call_button') && has(relatedData, 'show_sms_button')
          }
        />
      )}
    </>
  );
};
