const numeric = /^[0-9]+$/;
const compareIdentifiers = (a: string, b: string) => {
  const anum = numeric.test(a);
  const bnum = numeric.test(b);

  return a === b ? 0 : anum && !bnum ? -1 : bnum && !anum ? 1 : Number(a) < Number(b) ? -1 : 1;
};

export const isVersionGreaterOrEqual = (a: string, b: string) => {
  if (!a || !b) return false;
  const old = a.split('.');
  const faceLift = b.split('.');
  if (old.length < 3 || faceLift.length < 3) return false;

  const [old_major, old_minor, old_patch] = old;
  const [faceLift_major, faceLift_minor, faceLift_patch] = faceLift;

  return (
    (compareIdentifiers(old_major, faceLift_major) ||
      compareIdentifiers(old_minor, faceLift_minor) ||
      compareIdentifiers(old_patch, faceLift_patch)) >= 0
  );
};
