import { captureException } from '@sentry/react';
import { useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { prevStep } from 'services';
import type { PreviousStepPayload } from 'types';
import { SESSION_ITEMS } from 'types';
import { session } from 'utils/session';
import redirectResponse from 'libs/redirect-by-response';

export const useGoBack = () => {
  const [loading, setLoading] = useState(false);

  /**
   * @function goBack A function to navigate to a previous step in the onboarding process.
   *
   * @param {NavigateFunction} navigate react-router-dom `navigate` function to navigate to a different page
   * @return {*} void
   */
  const goBack = async (navigate: NavigateFunction) => {
    if (loading) return;

    const payload: PreviousStepPayload = {
      session_id: session.getItem(SESSION_ITEMS.SESSION_ID) as string,
    };

    setLoading(true);

    try {
      const response = await prevStep(payload);
      redirectResponse(response, navigate);
    } catch (error) {
      captureException(error);
      setLoading(false);
    }
  };

  return { goBack, loading };
};
