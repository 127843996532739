import { Text } from '../../atoms';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FORM_INPUTS, MASTER_STEPS } from 'types';
import { FormInput } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { OTPResendCountDown } from '@talk360/components/molecules/otpResendCountDown';
import { useSendSegmentEvents } from 'hooks';
import { NavigationBar } from '../navigationBar';

// TODO: Fix the upward reference of this component conflicting with the atomic design pricples of the app
import { FormFrame } from '../../templates';

interface Props {
  formInputType?: FORM_INPUTS;
  confirmPhoneNumber: (code: string) => void;
  resendAllowed?: boolean;
  resendOTP?: () => void;
  hasBack?: boolean;
  phoneNumber: string;
  loading: boolean;
  segmentEventName: string;
}

interface FormFields {
  otp: string[];
}

export const OTP: FC<Props> = ({
  formInputType = FORM_INPUTS.OTP_INPUT,
  resendAllowed = true,
  resendOTP,
  hasBack,
  confirmPhoneNumber,
  phoneNumber,
  loading,
  segmentEventName,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { sendTrack } = useSendSegmentEvents(segmentEventName);

  const btnRef = useRef<HTMLButtonElement>(null);

  const { register, setValue, watch, trigger } = useForm<FormFields>({
    defaultValues: {
      otp: [],
    },
  });

  const formValues: FormFields = watch();

  useEffect(() => {
    if (!formValues.otp.length || !formValues.otp.every((item) => item)) return;
    confirmPhoneNumber(formValues.otp.join(''));
    const otp = document.querySelector('#otp0') as HTMLInputElement;
    otp.focus();
    otp.blur();
  }, [formValues.otp]);

  const onClickBack = () => {
    navigate(MASTER_STEPS.msisdn_screen_step.ROUTE);
  };

  const handleResendOTP = () => {
    sendTrack('resend_sms');
    resendOTP && resendOTP();
  };

  const initiateOTPAutocompleteDecoy = () => {
    const decoyOtp = sessionStorage.getItem('otp');
    const otp = Array.from(decoyOtp || '');
    if (otp.length === 6) {
      setValue('otp', otp);
      trigger();
    }
  };

  return (
    <>
      <NavigationBar isNavigating={loading} hasBack={hasBack} onClickBack={onClickBack} />
      <Text variant="header" className="mb-0">
        {t('otp_page.main_header')}
      </Text>
      <Text variant="subheader" className="mb-0">
        {t('otp_page.sub_header', { phoneNumber: `+${phoneNumber}` })}
      </Text>

      <FormFrame
        formClassName="!flex-grow-0"
        primaryButtonText="nill"
        hasPrimaryButton={false}
        noBottomPadding={true}
      >
        <FormInput
          type={formInputType}
          name="otp"
          loading={loading}
          register={register}
          setValue={setValue}
          formValues={formValues}
        />
      </FormFrame>
      <OTPResendCountDown
        resendOTP={handleResendOTP}
        resendAllowed={resendAllowed}
        phoneNumber={phoneNumber}
      />
      <button
        id="decoy"
        ref={btnRef}
        onClick={() => {
          initiateOTPAutocompleteDecoy();
        }}
        className="hidden"
      ></button>
    </>
  );
};
