import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import i18 from 'i18n';
import type { AnyObject } from 'yup/lib/object';
import type { Field, Params } from 'types';

const t = i18.t; // translation instance

export type SubValidators = 'trim' | 'email' | 'min' | 'max' | 'required';

interface Schema {
  [x: string]: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
}

/**
 * @function To get a schema based on a field type i.e the kind of input e.g `input`, `labelled-input`, e.t.c
 *
 * @param {Schema} schema The Yup schema to be returned
 * @param {Field} fields containing details for the schema. For example, `name` of the field, `validationType` - The type of validation for the field e.t.c
 * @return {*}  {Schema}
 */
const schemaIterator = (schema: Schema, fields: Field): Schema => {
  const { name, validationType, validations = [] } = fields;
  if (!Yup[validationType]) {
    return schema;
  }
  let validator = Yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type as SubValidators]) return;
    const refactoredParams = params.map((item) =>
      typeof item === 'string' ? t(item).toString() : item,
    ) as Params;
    validator = validator[type as SubValidators](...refactoredParams);
  });

  const isObject = name.indexOf('.') >= 0;

  if (isObject) {
    const reversePath = name.split('.').reverse();

    let currNestedObject = { [reversePath[0]]: validator };

    reversePath.slice(1).forEach((path) => {
      if (!isNaN(Number(path))) {
        return { array: Yup.array().of(Yup.object().shape(currNestedObject)) };
      }

      if (currNestedObject.array) {
        return { [path]: currNestedObject.array };
      }

      currNestedObject = { [path]: Yup.object().shape(currNestedObject) } as unknown as Schema;
    });

    return currNestedObject;
  }

  schema[name] = validator;
  return schema;
};

/**
 * @function createSchema A function to create yup schemas dynamically based on different input fields.
 *
 * @param {Field[]} [data=[]]
 * @return {*}
 */
export const createSchema = (data: Field[] = []) => {
  const reducedSchema = data.reduce(schemaIterator, {});
  const validationSchema = Yup.object().shape(reducedSchema);
  return yupResolver(validationSchema);
};
