import { useTheme } from '@talk360/contexts/themeContext';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

type ClassMapProp<T extends keyof typeof classMap> = Exclude<keyof (typeof classMap)[T], 'default'>;
interface Props {
  className?: string;
  underlined?: boolean;
  variant?: 'header' | 'subheader' | 'body';
  fontWeight?: keyof typeof fontWeights;
  color?: ClassMapProp<'color'>;
}

const variants = {
  header: 'text-header',
  subheader: 'text-subheader',
  body: 'text-body',
};

const fontWeights = {
  default: 'font-normal',
  bold: 'font-semibold',
  normal: 'font-normal',
  light: 'font-light',
};

// Class map, if value is an array, the first value is for pre-isFacelift, the second value is for isFacelift
const classMap = {
  fontWeight: {
    default: 'font-normal',
    bold: 'font-semibold',
    normal: 'font-normal',
    light: 'font-light',
  },
  color: {
    default: ['text-white', 'text-secondary'],
    white: 'text-white',
    gray: 'text',
    secondary: 'text-secondary',
    black: 'text-black',
  },
};

export const Text = ({
  children,
  className,
  underlined,
  variant = 'body',
  fontWeight,
  color,
}: PropsWithChildren<Props>) => {
  const { isFacelift } = useTheme();

  const getValue = (value: string | string[]): string => {
    if (Array.isArray(value)) {
      return value[isFacelift ? 1 : 0];
    }
    return value;
  };

  return (
    <p
      className={classNames(
        getValue(classMap.color[color || 'default']),
        variants[variant],
        {
          underline: underlined,
        },
        fontWeight ? fontWeights[fontWeight] : '',
        className,
      )}
    >
      {children}
    </p>
  );
};
