import { Trans } from 'react-i18next';
import { Text } from '../text';
import { toast as _toast } from 'react-toastify';
import { LinkContainer } from '@talk360/components/molecules/emailLink';

type ToastType = 'error' | 'success';

interface ToastProps {
  closeToast?: () => void;
  message?: string;
  type?: ToastType;
}

export const Toast = ({ closeToast, message, type = 'error' }: ToastProps) => {
  const color = type === 'success' ? 'bg-success' : 'bg-danger';

  return (
    <div
      className={`flex justify-between items-center ${color} rounded-lg p-4 text-sm shadow-[0px_3px_5px_-1px_rgb(0,0,0,0.2),0px_6px_10px_0px_rgb(0,0,0,0.14),0px_1px_18px_0px_rgb(0,0,0,0.12)]`}
    >
      <Text
        fontWeight="light"
        className="text-light-900 !text-white !text-sm w-[calc(100%-60px-5px)]"
      >
        {<Trans i18nKey={message} components={[<LinkContainer />, <LinkContainer />]} />}
      </Text>
      <button
        className="px-2 py-1 border-light-900 border rounded text-white text-sm w-[60px]"
        onClick={closeToast}
        data-cy="close-toast"
      >
        Close
      </button>
    </div>
  );
};

export const toast = (msg: string, type?: ToastType) =>
  _toast(<Toast message={msg} type={type} />, { autoClose: 5000, hideProgressBar: true });
