import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useTheme } from '@talk360/contexts/themeContext';
import { Button, Text } from '../../atoms';
import { useCountdown } from '@talk360/contexts/countdownContext';

interface Props {
  resendOTP(): void;
  resendAllowed: boolean;
  phoneNumber: string;
}

const getTimeoutDate = (): Date => {
  return moment().add(30, 'seconds').toDate();
};

export const OTPResendCountDown = ({ resendOTP, resendAllowed }: Props) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();
  const {
    timeLeft: [seconds, minutes],
    completed,
    startCountDown,
    stopCountdown,
  } = useCountdown();

  const resetCountdown = (): void => {
    startCountDown(getTimeoutDate());
  };

  useEffect(() => {
    resetCountdown();
    return () => {
      stopCountdown();
    };
  }, []);

  const resend = () => {
    resendOTP();
    resetCountdown();
  };

  const RenderCountdown: FunctionComponent = () => {
    if (completed) {
      if (resendAllowed) {
        if (isFacelift) {
          return (
            <div className="flex mt-4">
              <Button className="rounded-full px-4 w-fit" data-cy="resend" onClick={() => resend()}>
                {t('otp_page.resend_code_facelift')}
              </Button>
            </div>
          );
        } else {
          return (
            <div className="flex items-center mt-6">
              <Button
                type="button"
                data-cy="resend"
                className="flex items-center bg-transparent shadow-none p-0 w-fit text-lg leading-[22px]"
                onClick={() => resend()}
              >
                {t('otp_page.button')} <span className="ml-1 underline">{t('otp_page.link')}</span>
              </Button>
            </div>
          );
        }
      }
    } else {
      if (isFacelift) {
        return (
          <div className="flex mt-4">
            <Button
              className="rounded-full px-4 w-fit disabled:!bg-[#E0ECF1] text-secondary shadow-none"
              disabled
            >
              {t('otp_page.resend_code_facelift')} ({(minutes || 0).toString()}:
              {seconds.toString().padStart(2, '0')})
            </Button>
          </div>
        );
      } else {
        return (
          <Text variant="subheader" className="opacity-60 mt-6 !mb-0 !text-white">
            {t('otp_page.resend_code')} 0:
            {seconds.toString().length > 1 ? seconds.toString() : `0${seconds.toString()}`}
          </Text>
        );
      }
    }

    return <></>;
  };

  return <RenderCountdown />;
};
