import { type PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Backdrop } from '../../atoms';
import { useTheme } from '@talk360/contexts/themeContext';

interface Props {
  isOpen: boolean;
  close: () => void;
  modal?: boolean;
}

export const Sheet: React.FunctionComponent<PropsWithChildren<Props>> = ({
  isOpen,
  close,
  modal = false,
  children,
}) => {
  const { isFacelift } = useTheme();

  return (
    <>
      <div
        className={classNames(
          'ease-sheet-in-out fixed left-0 right-0 px-4 py-5 duration-200 z-10',
          { 'bg-[#f7f7f7] h-[95%] rounded-t-2xl': !isFacelift },
          { 'bg-white': isFacelift },
          isFacelift && !modal ? 'h-full' : '',
          // !modal && isOpen ? 'bottom-0' : `${!isFacelift ? 'bottom-[-95%]' : 'bottom-[-100%]'}`,
          { 'bottom-[-95%]': !modal && !isOpen && !isFacelift },
          { 'bottom-[-100%]': !modal && !isOpen && isFacelift },
          { 'bottom-0': !modal && isOpen },
          {
            'rounded-lg w-4/5 left-1/2 bottom-1/2 -translate-x-1/2 translate-y-1/2 max-h-96': modal,
          },
          {
            'bottom-[-300%]': modal && !isOpen,
          },
        )}
      >
        {children}
      </div>
      {isOpen && <Backdrop onClose={close} />}
    </>
  );
};
