import { forwardRef } from 'react';
import type { InputProps } from 'types';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ id, className, dataCy, ...props }, ref) => {
    return (
      <input
        id={id}
        {...props}
        className={className}
        autoComplete="off"
        ref={ref}
        data-cy={dataCy}
      />
    );
  },
);
