import type { ChangeEvent } from 'react';
import { Fragment, useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import type { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FORM_INPUTS } from '@talk360/types';
import type { CountryProps, PhoneCountryForm, PhoneNumberFormFields } from '@talk360/types';

import { Icon, Text, Input } from '../../atoms';
import CountryPhoneDropdown from './countryDropdown';
import { useTheme } from '@talk360/contexts/themeContext';

interface CountryPhoneInput {
  type?: FORM_INPUTS.COUNTRY_INPUT | FORM_INPUTS.PHONE_NUMBER_INPUT;
  onChange(value: PhoneCountryForm): void;
  formValues: PhoneCountryForm;
  errors: FieldErrors<PhoneNumberFormFields & PhoneCountryForm>;
}

export const CountryPhoneInput = ({
  type = FORM_INPUTS.PHONE_NUMBER_INPUT,
  onChange,
  formValues,
  errors,
}: CountryPhoneInput) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const country = formValues.country;
  const phone_number = formValues.phone_number;

  const toggleCountryDropdown = () => setIsOpen((open) => !open);

  const handleCountryChange = (_country: CountryProps): void => {
    onChange({ country: _country, phone_number });
  };

  const handlePhoneChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const startsWithPlus = value.startsWith('+');

    let fullNumber = value;
    if (!startsWithPlus) {
      fullNumber = `${formValues.country.dialing_code}${value}`;
    }

    try {
      const {
        country: _country,
        nationalNumber,
        countryCallingCode,
      } = parsePhoneNumber(fullNumber);
      onChange({
        phone_number: nationalNumber,
        country: { code: _country as string, dialing_code: `+${countryCallingCode}` },
      });
    } catch (e) {
      onChange({ phone_number: value, country });
    }
    return;
  };

  return (
    <Fragment>
      {isFacelift && <Text className="!text-sm mb-2">{t('msisdn_page.phone_number')}</Text>}
      <div
        className={classNames(
          'border rounded-lg',
          { 'border-primary': !isFacelift },
          { 'border-secondary/50': isFacelift },
          {
            'border-red-400 overflow-hidden bg-white':
              errors.phone_country?.country || errors.phone_country?.phone_number || errors.country,
          },
        )}
      >
        {type === FORM_INPUTS.PHONE_NUMBER_INPUT ? (
          <div
            className={classNames(
              'country-flag-input flex items-center rounded-lg bg-white pl-4 overflow-hidden',
              { 'h-12': isFacelift, 'h-16': !isFacelift },
            )}
          >
            <button
              onClick={toggleCountryDropdown}
              type="button"
              className="flex items-center"
              data-cy="country"
            >
              <div className={`mr-2 flag:${country?.code}`} />
              <Icon name="chevron-down" className="mr-3" />

              {isFacelift && <span className="block mr-2 h-8 border-r border-secondary/50"></span>}

              <span data-cy="dial-code">{country?.dialing_code}</span>
            </button>

            <Input
              type="tel"
              onChange={handlePhoneChange}
              inputMode="tel"
              className={classNames('pl-2 flex-grow rounded-lg no-spinner focus:outline-none', {
                'p-3 h-12': isFacelift,
                'h-16 p-4': !isFacelift,
              })}
              placeholder={t('country_phone_input.placeholder')}
              value={phone_number}
              dataCy="phone-number"
            />
          </div>
        ) : (
          <button
            onClick={toggleCountryDropdown}
            type="button"
            className="flex justify-between items-center rounded-lg bg-white pl-4 h-16 w-full"
            data-cy="country"
          >
            <div className="flex">
              <div
                className={`mr-4 flag:${country?.code || 'LT'} ${
                  country?.code ? 'grayscale-0' : 'grayscale'
                }`}
              />
              <Text>{country?.name || t('country_phone_input.country_placeholder')}</Text>
            </div>

            <Icon name="chevron-down" className="mr-3" />
          </button>
        )}
      </div>

      <CountryPhoneDropdown
        isOpen={isOpen}
        type={type}
        closeDropdown={toggleCountryDropdown}
        setSelectedCountry={handleCountryChange}
      />
    </Fragment>
  );
};
