import { RATE_BASE_URL } from '@talk360/constants';
import { Api } from 'libs/api';
import type { RateResponse } from 'types';

/**
 * @function getCallRate Get call rate
 *
 * @param {string} currency The currency you'll like to get the call rates in e.g `$`, `€`, `£` e.t.c
 * @param {string} country_code The country code for which you'll like to get the calling rate. e.g `NL`, `ZA`, `NG` e.t.c
 */
export const getCallRate = async (currency: string, country_code: string) =>
  Api.get<RateResponse>(`${RATE_BASE_URL as string}${currency}/call_rates/${country_code}.json`);
