import { loadFonts } from '@talk360/libs/fonts';
import { useEffect } from 'react';

export const useFont = (): void => {
  useEffect(() => {
    const families = [];

    families.push('Noto Sans:400;500;600');
    families.push('Poppins:400,600');

    loadFonts(families);
  }, []);
};
