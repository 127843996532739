import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import classNames from 'classnames';

import type { CurrencyProps } from '@talk360/types/molecules';
import type { CurrencyFormFields } from '@talk360/types';
import { useTheme } from '@talk360/contexts/themeContext';

import { Icon, Input } from '../../atoms';
import { CurrencyDropdown } from './currencyDropdown';

interface Props {
  onChange(currency: string): void;
  currencies: CurrencyProps[];
  errors: FieldErrors<CurrencyFormFields>;
  name: string;
  value?: string;
}

export const CurrencyInput: FC<Props> = ({ onChange, currencies, errors, name, value }) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  const defaultCurrency = currencies.find((currency) => currency.code === value);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyProps>({
    code: defaultCurrency?.code || '',
    name: defaultCurrency?.name || '',
    symbol: defaultCurrency?.symbol || '',
  });

  const toggleCurrencyDropdown = () => setIsOpen((open) => !open);

  return (
    <Fragment>
      <div
        onClick={toggleCurrencyDropdown}
        className={classNames(
          'flex justify-between items-center rounded-lg bg-white border border-[rgba(0,0,0,0.08)]',
          {
            '!border-red-600': get(errors, name),
            'h-16': !isFacelift,
            'h-12': isFacelift,
          },
        )}
        data-cy="currency"
      >
        <Input
          type="text"
          value={
            selectedCurrency.name
              ? `${t(`currencies.${selectedCurrency.code}`)} (${selectedCurrency.symbol})`
              : ''
          }
          readOnly
          className="h-16 p-4 flex-grow rounded-lg focus:outline-none bg-transparent"
          placeholder={t('currency_input.placeholder')}
        />

        <button
          type="button"
          aria-label="toggle-currency-dropdown"
          className="flex items-center"
          data-cy="toggle-currency-dropdown"
        >
          <Icon name="chevron-down" className="mr-3" />
        </button>
      </div>

      <CurrencyDropdown
        isOpen={isOpen}
        currencies={currencies}
        closeDropdown={toggleCurrencyDropdown}
        setSelectedCurrency={(currency) => {
          setSelectedCurrency(currency);
          onChange(currency.code);
        }}
      />
    </Fragment>
  );
};
