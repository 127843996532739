import { Fragment, useEffect, useState } from 'react';
import type { CurrencyProps } from 'types/molecules';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@talk360/contexts/themeContext';
import { Icon } from '@talk360/components/atoms/icon';
import { Sheet } from '../sheet';
import { SearchInput } from '../searchInput';
import { CurrencyList } from './currencyList';
import { Text } from '@talk360/components/atoms';

interface CurrencyDropdownProps {
  isOpen: boolean;
  currencies: CurrencyProps[];
  closeDropdown: () => void;
  setSelectedCurrency(currency: CurrencyProps): void;
  onOpen?: () => void;
}

export const CurrencyDropdown = ({
  isOpen,
  currencies,
  closeDropdown,
  setSelectedCurrency,
  onOpen,
}: CurrencyDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredCurrencies, setFilteredCurrencies] = useState<CurrencyProps[]>([...currencies]);

  const { t } = useTranslation();

  const { isFacelift } = useTheme();

  useEffect(() => {
    isOpen && onOpen && onOpen();
  }, [isOpen]);

  const filterCurrencyList = (searchValue: string): void => {
    if (!searchValue) return setFilteredCurrencies(currencies);

    const result = currencies.filter((currency) => {
      return (
        currency.code.toLowerCase().includes(searchValue.toLowerCase()) ||
        currency.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    });

    setFilteredCurrencies(result);
  };

  const resetDropdown = (): void => {
    setSearchValue('');
    setFilteredCurrencies(currencies);
  };

  const handleSelectCurrency = (currency: CurrencyProps): void => {
    setSelectedCurrency(currency);
    closeDropdown();
    resetDropdown();
  };

  const onCancel = (): void => {
    closeDropdown();
    resetDropdown();
  };

  return (
    <Fragment>
      <Sheet isOpen={isOpen} close={closeDropdown}>
        {isFacelift && (
          <div className="flex mb-2 justify-between">
            <Text variant="header" className="mt-4">
              {t('country_phone_input.search_country')}
            </Text>
            <button
              type="button"
              aria-label="back-sheet"
              className="ml-4 flex justify-center items-center w-7 h-7 bg-[#E0ECF1] rounded-full"
              onClick={onCancel}
              data-cy="cancel"
            >
              <Icon name="close" size={20} />
            </button>
          </div>
        )}
        <div className="flex items-center mb-4">
          <SearchInput
            isOpen={isOpen}
            currentValue={searchValue}
            placeholder={t('currency_input.search_placeholder')}
            emittedSearchValue={(value) => {
              setSearchValue(value);
              filterCurrencyList(value);
            }}
            dataCy="search"
          />
          {!isFacelift && (
            <button
              onClick={onCancel}
              type="button"
              className="ml-4 flex-shrink-0 text-[#007AFF] font-normal text-base w-[54px]"
              data-cy="cancel"
            >
              Cancel
            </button>
          )}
        </div>

        <CurrencyList currencies={filteredCurrencies} onSelectCurrency={handleSelectCurrency} />
      </Sheet>
    </Fragment>
  );
};
