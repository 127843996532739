import type { ReactElement } from 'react';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import type { RouteI } from '@talk360/types';
import { SESSION_ITEMS } from '@talk360/types/session';
import { session } from '@talk360/utils/session';
import { ROUTES } from '@talk360/types';
import { Loader } from '@talk360/components';

import StartPage from './start';

const SendVerificationPage = lazy(() => import('./sendVerification'));
const EmailVerificationPage = lazy(() => import('./EmailVerification'));
const InfoPage = lazy(() => import('./infoPage'));
const AgentCodePage = lazy(() => import('./agentCode'));
const ImportContactsPage = lazy(() => import('./importContacts'));
const CurrencyPage = lazy(() => import('./currency'));
const CountryPage = lazy(() => import('./country'));
const TestCallPage = lazy(() => import('./testCall'));
const LoginPage = lazy(() => import('./login'));
const RatesPage = lazy(() => import('./rates'));
const TermsOfConditionPage = lazy(() => import('./tandc'));
const PhoneInput = lazy(() => import('./PhoneInput'));
const PhoneVerificationOtpSinch = lazy(() => import('./PhoneVerificationOtpSinch'));
const PhoneVerificationOtpFirebase = lazy(() => import('./PhoneVerificationOtpFirebase'));
const FlashCallPage = lazy(() => import('./FlashCallWaitScreen'));
const ForceUpdatePage = lazy(() => import('./ForceUpdate'));

// Playground
const Playground = lazy(() => import('./Playground'));

interface IRoute extends RouteI {
  protectedRoute?: boolean;
  matchGlobalRoutes?: boolean;
}

const createSuspensefulScreen = (Component: React.ComponentType) => () =>
  (
    <Suspense fallback={<Loader className="flex flex-1 items-center justify-center" />}>
      <Component />
    </Suspense>
  );

const routes: IRoute[] = [
  { path: '/', component: StartPage },
  {
    path: ROUTES.PHONE_VERIFICATION,
    component: createSuspensefulScreen(SendVerificationPage),
    protectedRoute: true,
    matchGlobalRoutes: true,
  },
  {
    path: ROUTES.PHONE_INPUT,
    component: createSuspensefulScreen(PhoneInput),
    protectedRoute: true,
  },
  {
    path: ROUTES.PHONE_VERIFICATION_OTP_SINCH,
    component: createSuspensefulScreen(PhoneVerificationOtpSinch),
    protectedRoute: true,
  },
  {
    path: ROUTES.PHONE_VERIFICATION_OTP_FIREBASE,
    component: createSuspensefulScreen(PhoneVerificationOtpFirebase),
    protectedRoute: true,
  },
  {
    path: ROUTES.EMAIL_VERIFICATION,
    component: createSuspensefulScreen(EmailVerificationPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.INFO_PAGE,
    component: createSuspensefulScreen(InfoPage),
    protectedRoute: true,
    matchGlobalRoutes: true,
  },
  {
    path: ROUTES.AGENT_CODE,
    component: createSuspensefulScreen(AgentCodePage),
    protectedRoute: true,
  },
  {
    path: ROUTES.IMPORT_CONTACTS,
    component: createSuspensefulScreen(ImportContactsPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.CURRENCY,
    component: createSuspensefulScreen(CurrencyPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.COUNTRY,
    component: createSuspensefulScreen(CountryPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.SHOW_RATES,
    component: createSuspensefulScreen(RatesPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.TEST_CALL,
    component: createSuspensefulScreen(TestCallPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.LOGIN,
    component: createSuspensefulScreen(LoginPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.TERMS_AND_CONDITION,
    component: createSuspensefulScreen(TermsOfConditionPage),
    protectedRoute: false,
  },
  {
    path: ROUTES.PLAYGROUND,
    component: createSuspensefulScreen(Playground),
    protectedRoute: false,
  },
  {
    path: ROUTES.FLASH_CALL,
    component: createSuspensefulScreen(FlashCallPage),
    protectedRoute: true,
  },
  {
    path: ROUTES.FORCE_UPDATE,
    component: createSuspensefulScreen(ForceUpdatePage),
    protectedRoute: false,
  },
];

const ProtectedRoute = ({ children }: { children: ReactElement }): ReactElement => {
  const sessionId = session.getItem(SESSION_ITEMS.SESSION_ID);
  const location = useLocation();

  if (!sessionId) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, component: Component, protectedRoute, matchGlobalRoutes }) => (
        <Route
          path={`${path}${matchGlobalRoutes ? '/*' : ''}`}
          key={path}
          element={
            protectedRoute ? (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ) : (
              <Component />
            )
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
