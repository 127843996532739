import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import type { CountryProps } from '@talk360/types';
import { FORM_INPUTS } from '@talk360/types';
import { useTheme } from '@talk360/contexts/themeContext';

import { Text, Empty } from '../../atoms';

type CountryListProps = {
  countries: CountryProps[];
  type: FORM_INPUTS.COUNTRY_INPUT | FORM_INPUTS.PHONE_NUMBER_INPUT;
  onSelectCountry: (country: CountryProps) => void;
};

export const CountryList: FC<CountryListProps> = ({ countries, type, onSelectCountry }) => {
  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  return (
    <div className="overflow-y-auto rounded-[12px_12px_0px_0px] bg-white h-[calc(100%-56px)]">
      {countries.length === 0 ? (
        <Empty />
      ) : (
        <ul className="list-none">
          {countries.map((country) => {
            return (
              <li
                key={country.name}
                className={classNames('flex items-center py-5 cursor-pointer', {
                  'px-4': !isFacelift,
                })}
                onClick={() => onSelectCountry(country)}
                data-cy={country.code}
              >
                <div className={classNames(`mr-4`, `flag:${country.code}`)} />
                {type === FORM_INPUTS.PHONE_NUMBER_INPUT && (
                  <Text
                    color="black"
                    className={classNames(
                      { 'mr-6 w-[50px]': !isFacelift },
                      { 'ml-6 order-2 text-right': isFacelift },
                    )}
                  >
                    {country.dialing_code}
                  </Text>
                )}
                <Text className="flex-grow" color="black">
                  {t(`countries.${country.code}`)}
                </Text>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
