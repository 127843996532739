import type { IDevice } from 'libs/device';
import type { AgentCodeFormFields, Field } from 'types';
import type { AllSteps } from 'types/routes';

export enum Endpoints {
  START_SESSION = 'session_start',
  NEXT_STEP = 'submit_step',
  PREVIOUS_STEP = 'go_back',
  REVERT_STEP = 'revert_to',
  CHECK_REFERRAL_CODE = 'check_referral_code',
}

export interface UserAnswer extends AgentCodeFormFields {
  calling_country?: string;
}

interface User {
  email: string;
  currency: string;
}

export interface SessionPayload extends IDevice {
  tenant_id: number;
  flow?: string;
}

export interface PayloadData {
  msisdn?: string;
  jwt?: string;
  user?: User;
  user_answers?: Partial<UserAnswer>;
}

export interface PreviousStepPayload {
  session_id: string;
}

export interface NextStepPayload extends PreviousStepPayload {
  current_step: string;
  data: PayloadData;
}

export interface RevertStepPayload extends PreviousStepPayload {
  step_name: AllSteps;
}

export interface VerifyReferralCodePayload {
  session_id: string;
  referral_code: string;
}

export interface VerifyReferralCodeResponse {
  referral_code_valid: boolean;
  referral_type: 'friend' | 'agent';
  reward: string;
}

export interface NextStepResponse<Data = unknown> {
  back_allowed?: boolean;
  next_step: {
    type: AllSteps;
    details: {
      title: string;
      form_elements: Field[];
      data_to_submit: PayloadData;
      related_data: Record<string, unknown>;
      skippable: boolean;
    };
  };
  stepData?: Data;
}

export interface NextStepResponseB<Data = unknown> {
  type: AllSteps;
  back_allowed?: boolean;
  details: {
    title: string;
    form_elements: Field[];
    data_to_submit: PayloadData;
    related_data: Record<string, unknown>;
    skippable: boolean;
    user_id: string;
    token: string;
    new_user: boolean;
  };
  stepData?: Data;
}

export interface SessionResponse extends NextStepResponse {
  session_id: string;
}

export interface Rate {
  mobile_rate: string;
  landline_rate: string;
  start_fee?: string;
}

export interface RateResponse extends Rate {
  code: string;
}
