import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useNativeGoBack, useGoBack } from '@talk360/hooks';
import { useTheme } from '@talk360/contexts/themeContext';
import { Icon, Text } from '../../atoms';

interface Props {
  children?: JSX.Element;
  isNavigating?: boolean;
  hasBack?: boolean;
  hasNext?: boolean;
  hasBackUrl?: boolean;
  backClass?: string;
  nextClass?: string;
  wrapperClass?: string;
  onClickBack?: () => void;
  onClickNext?: () => void;
}

export const NavigationBar = ({
  children,
  isNavigating = false,
  hasBack = true,
  onClickBack,
  backClass = '',
  hasNext = false,
  hasBackUrl = false, // To state if there's incoming url for back navigation
  onClickNext,
  nextClass = '',
  wrapperClass = '',
}: Props) => {
  const navigate = useNavigate();
  const { isFacelift } = useTheme();

  const baseButtonClasses = `flex justify-center items-center ${
    isFacelift ? 'w-7 h-7' : 'w-10 h-10'
  } rounded-full`;

  const { goBack, loading } = useGoBack();

  const handleBackNavigation = () => {
    onClickBack && onClickBack();
    !hasBackUrl && goBack(navigate);
  };

  useNativeGoBack(isNavigating, hasBack, loading, handleBackNavigation);

  return (
    <div className={'flex items-center justify-between h-10 ' + wrapperClass}>
      {hasBack && (
        <button
          type="button"
          aria-label="back"
          data-cy="back"
          onClick={handleBackNavigation}
          disabled={isNavigating && loading}
          className="flex flex-row items-center overflow-hidden"
        >
          <div
            className={classNames(
              baseButtonClasses,
              backClass,
              { 'bg-black/20': !isFacelift },
              { 'bg-[#E0ECF1]': isFacelift },
            )}
          >
            <Icon name="arrow-left" />
          </div>
          {isFacelift && <Text className="ml-4 font-semibold">Back</Text>}
        </button>
      )}
      {children}
      {hasNext && (
        <button
          type="button"
          aria-label="next"
          className={classNames(
            baseButtonClasses,
            nextClass,
            { 'bg-black/20': !isFacelift },
            { 'bg-[#E0ECF1]': isFacelift },
          )}
          onClick={onClickNext}
        >
          <Icon name="arrow-right" />
        </button>
      )}
    </div>
  );
};
