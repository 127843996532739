import type { ReactNode } from 'react';
import { useEffect, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@talk360/contexts/themeContext';

import { SearchInput } from '../searchInput';
import { Sheet } from '../sheet';
import { Icon, Text } from '../../atoms';

interface Props {
  isOpen: boolean;
  onOpen?: () => void;
  options: string[];
  placeholder: string;
  closeDropdown: () => void;
  searchValue(value: string): void;
  children: ReactNode;
}

export const Dropdown = ({
  isOpen,
  onOpen,
  closeDropdown,
  searchValue,
  placeholder,
  children,
}: Props) => {
  const [search, setSearchValue] = useState<string>('');

  const { t } = useTranslation();
  const { isFacelift } = useTheme();

  useEffect(() => {
    if (!isOpen) {
      resetDropdown();
      setSearchValue('');
    } else {
      onOpen && onOpen();
    }
  }, [isOpen]);

  const resetDropdown = () => {
    setSearchValue('');
  };

  const onCancel = () => {
    closeDropdown();
    resetDropdown();
  };

  return (
    <Fragment>
      <Sheet isOpen={isOpen} close={closeDropdown}>
        {isFacelift && (
          <div className="flex mb-2 justify-between">
            <Text variant="header" className="mt-4">
              {t('country_phone_input.search_country')}
            </Text>
            <button
              type="button"
              aria-label="back-sheet"
              className="ml-4 flex justify-center items-center w-7 h-7 bg-[#E0ECF1] rounded-full"
              onClick={onCancel}
              data-cy="cancel"
            >
              <Icon name="close" size={20} />
            </button>
          </div>
        )}
        <div className="flex items-center mb-4">
          <SearchInput
            isOpen={isOpen}
            currentValue={search}
            placeholder={placeholder}
            dataCy="search"
            emittedSearchValue={(value) => {
              setSearchValue(value);
              searchValue(value);
            }}
          />
          {!isFacelift && (
            <button
              onClick={onCancel}
              type="button"
              className="ml-4 flex-shrink-0 text-[#007AFF] font-normal text-base w-[54px]"
              data-cy="cancel"
            >
              Cancel
            </button>
          )}
        </div>

        <div className="overflow-y-auto rounded-[12px_12px_0px_0px] bg-white h-[calc(100%-56px)]">
          <ul className="list-none">{children}</ul>
        </div>
      </Sheet>
    </Fragment>
  );
};
