import { Input } from '../../atoms/';
import { useEffect } from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import type {
  FieldErrors,
  FieldValue,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';

import type {
  CountryOrPhone,
  FORM_INPUT_TYPES,
  PhoneCountryForm,
  PossibleFormValues,
  CurrencyProps,
  inputModes,
} from '@talk360/types';
import { InputMode, FORM_INPUTS } from '@talk360/types';
import { CountryPhoneInput } from '../countryPhoneInput';
import { CurrencyInput } from '../currencyInput';
import { LabelledInput } from '../labelledInput';
import { OTPInput } from '../otpInput';
import { Country } from '../country';
import { useTheme } from '@talk360/contexts/themeContext';

interface Prop<T extends FieldValues> {
  type: FORM_INPUTS;
  name: Path<T>;
  input_type?: FORM_INPUT_TYPES;
  options?: CurrencyProps[] | string[] | unknown[];
  loading?: boolean;
  label?: string;
  id?: string;
  className?: string;
  placeholder?: string;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  trigger?: UseFormTrigger<T>;
  formValues: T | FieldValue<T>;
  errors?: FieldErrors<T>;
}

export const FormInput = <T extends FieldValues>({
  type,
  id,
  input_type,
  label,
  placeholder,
  name,
  className,
  loading,
  options = [],
  formValues,
  errors = {},
  setValue,
  register,
  trigger,
}: Prop<T>) => {
  useEffect(() => {
    register(name);
  }, []);

  const { isFacelift } = useTheme();

  const onChange = (name: Path<T>, value: PossibleFormValues) => {
    setValue(name, value as PathValue<T, Path<T>>);
    trigger && trigger();
  };

  const renderLabelledInput = () => (
    <LabelledInput
      label={label as string}
      name={name}
      placeholder={placeholder}
      id={id}
      inputMode={InputMode[input_type || 'text'] as inputModes}
      type={input_type || 'text'}
      onChange={({ target: { value } }) => onChange(name, value)}
      value={_get(formValues, name)}
    />
  );
  const renderOTPInput = () => (
    <OTPInput
      value={_get(formValues, name)}
      emittedOTP={(value) => onChange(name, value)}
      loading={loading}
    />
  );
  const renderInput = () => {
    return (
      <Input
        onChange={({ target: { value } }) => onChange(name, value)}
        placeholder={placeholder}
        className={classNames(
          'focus:outline-none focus:border-black/10',
          'rounded-lg text-black w-full mb-5',
          className,
          { 'border-2 !border-red-400': _get(errors, name) },
          { 'border border-secondary/50 p-3 h-12': isFacelift },
          { 'p-4 h-16': !isFacelift },
        )}
        type={input_type || 'text'}
        id={id}
        inputMode={InputMode[input_type || 'text'] as inputModes}
        value={_get(formValues, name)}
        name={name}
        dataCy={name}
      />
    );
  };
  const renderCountryPhoneInput = () => (
    <CountryPhoneInput
      onChange={(value) => onChange(name, value)}
      type={type as CountryOrPhone}
      formValues={formValues as unknown as PhoneCountryForm}
      errors={errors || {}}
    />
  );

  const renderCountryInput = () => (
    <Country
      onChange={(value) => onChange(name, value)}
      value={_get(formValues, name)}
      name={name}
      placeholder={placeholder as string}
      options={options as string[]}
      errors={errors || {}}
    />
  );

  const renderCurrencyInput = () => (
    <CurrencyInput
      onChange={(value) => onChange(name, value)}
      errors={errors || {}}
      currencies={options as CurrencyProps[]}
      name={name}
      value={_get(formValues, name)}
    />
  );

  switch (type) {
    case FORM_INPUTS.OTP_INPUT:
      return renderOTPInput();
    case FORM_INPUTS.LABELLED_INPUT:
      return renderLabelledInput();
    case FORM_INPUTS.PHONE_NUMBER_INPUT:
      return renderCountryPhoneInput();
    case FORM_INPUTS.COUNTRY_INPUT:
      return renderCountryInput();
    case FORM_INPUTS.CURRENCY_INPUT:
      return renderCurrencyInput();
    default:
      return renderInput();
  }
};
