import classNames from 'classnames';
import type { FC, FormEventHandler } from 'react';

import { combineClassName } from '@talk360/utils/functions';
import { Button } from '../../atoms';
import { useTheme } from '@talk360/contexts/themeContext';

interface Props {
  children: React.ReactNode;
  formClassName?: string;
  isLoading?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  primaryButtonText: string;
  primaryButtonClass?: string;
  hasPrimaryButton?: boolean;
  isPrimaryButtonDisabled?: boolean;
  primaryCyValue?: string;
  secondaryButtonText?: string;
  secondaryButtonClass?: string;
  isSecondaryButtonDisabled?: boolean;
  secondaryCyValue?: string;
  hasSecondaryButton?: boolean;
  buttonWrapperClassName?: string;
  onClickSecondaryButton?: () => void;
  noBottomPadding?: boolean;
  fixedPrimaryButton?: boolean;
}

export const FormFrame: FC<Props> = ({
  children,
  formClassName = '',
  isLoading,
  onSubmit,
  hasPrimaryButton = true,
  primaryButtonText,
  primaryButtonClass,
  isPrimaryButtonDisabled = false,
  primaryCyValue,
  hasSecondaryButton = false,
  secondaryButtonText,
  secondaryButtonClass,
  isSecondaryButtonDisabled = false,
  secondaryCyValue,
  onClickSecondaryButton,
  buttonWrapperClassName,
  noBottomPadding = false,
  fixedPrimaryButton = true,
}) => {
  const { isFacelift } = useTheme();
  return (
    <form
      onSubmit={onSubmit}
      className={combineClassName('flex-col flex flex-grow', formClassName)}
    >
      <div className={noBottomPadding ? '' : 'pb-[64px]'}>{children}</div>
      <div
        className={combineClassName(
          'flex flex-col bottom-4 w-[calc(100vw-2rem)]',
          buttonWrapperClassName,
          fixedPrimaryButton ? 'fixed' : '',
        )}
      >
        {hasPrimaryButton && (
          <Button
            className={primaryButtonClass}
            disabled={isLoading || isPrimaryButtonDisabled}
            data-cy={primaryCyValue}
          >
            {primaryButtonText}
          </Button>
        )}
        {hasSecondaryButton && (
          <Button
            className={classNames(secondaryButtonClass, { 'mt-4': isFacelift })}
            variant="secondary"
            onClick={() => onClickSecondaryButton && onClickSecondaryButton()}
            disabled={isLoading || isSecondaryButtonDisabled}
            data-cy={secondaryCyValue}
          >
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </form>
  );
};
