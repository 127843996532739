import { Api } from 'libs/api';
import type { SessionPayload, SessionResponse } from 'types';
import { Endpoints } from 'types';

/**
 * Http handler for starting a session
 *
 * @param {SessionPayload} data
 * @return {SessionResponse}
 */
export const startSession = (data: SessionPayload) =>
  Api.post<SessionPayload, SessionResponse>(Endpoints.START_SESSION, data);
