import type { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';

export enum TRIGGER_TYPES {
  SIGNUP_PIN_SENT = 'signup_pin_sent',
  PHONE_NUMBER_VERIFIED = 'phone_number_verified',
  SIGNUP_VERIFIED = 'signup_verified',
  PREPARE_OTP_CAPTURE = 'prepare_otp_capture',
}

export interface IDevice {
  app_version: string;
  features?: string;
  ip?: string;
  locale?: string;
  country?: string;
  os?: string;
  os_version?: string;
  manufacturer?: string;
  model?: string;
  timezone?: string;
  device_signature: string;
  device_language: string;
  native_recaptcha_verification_possible: boolean;
  flash_call_verification_possible: boolean;
  anonymous_identifier: string;
}

declare global {
  interface Window {
    device: {
      get_device_info(method: string): void;
      getPromocode?(method: string): void;
      getConsentMode?(method: string): void;
      trigger(event: TRIGGER_TYPES): void;
      prepare_otp_capture(): void;
      setCredentials(username: string, password: string, phoneNumber?: string): void;
      requestFlashCallPermissions(callback: string): void;
      startFlashCallVerification(phoneNumber: string, reference: string, callback: string): void;
      verifyUser(callback: string): void;
      sendSupportEmail?(emailAddress: string): void;
      prompt_sign_in(): void;
      openNativeBrowser?(url: string): void;
    };
    grecaptcha: RecaptchaVerifier & { reset(widgetId: number): void };
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
    recaptchaWidgetId: number;
    goBack(): void;
    segment(SEGMENT_ID: string | undefined): void;
  }
}
