import { useTranslation } from 'react-i18next';
import { Text } from '../text';

interface Props {
  message?: string;
}

export const Empty = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex justify-center items-center text-lg">
      <Text>{message || t('generic.not_found')}</Text>
    </div>
  );
};
