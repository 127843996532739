import { createContext, useContext, useEffect, useRef, useState } from 'react';

type Seconds = number;
type Hours = number;
type Minutes = number;
type Days = number;

type CountDown = [Seconds, Minutes, Hours, Days];

type CountdownContextProps = {
  timeLeft: CountDown;
  completed: boolean;
  started: boolean;
  startCountDown(targetDate: Date): void;
  stopCountdown(): void;
};

const getReturnValues = (countDown: number): CountDown => {
  const total = Math.abs(countDown) / 1000;
  // calculate time left
  const days: Days = Math.floor(total / (3600 * 24));
  const hours: Hours = Math.floor((total / 3600) % 24);
  const minutes: Minutes = Math.floor((total / 60) % 24);
  const seconds: Seconds = Math.floor(total % 60);

  return [seconds, minutes, hours, days];
};

export const CountdownContext = createContext<CountdownContextProps | null>(null);

export const CountdownProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [countDown, setCountDown] = useState(0);
  const [started, setStarted] = useState(false);
  const interval = useRef<null | ReturnType<typeof setTimeout>>(null);

  const startCountDown = (targetDate: Date) => {
    const countDownDate = new Date(targetDate).getTime() + 100;
    setCountDown(countDownDate - new Date().getTime());
    setStarted(true);
    interval.current = setInterval(() => {
      const diff = countDownDate - new Date().getTime();
      setCountDown(diff);
      if (diff <= 0) {
        clearInterval(interval.current as ReturnType<typeof setTimeout>);
        setStarted(false);
      }
    }, 1000);
  };

  const stopCountdown = () => {
    setStarted(false);
    setCountDown(0);
    clearInterval(interval.current as ReturnType<typeof setTimeout>);
  };

  useEffect(() => {
    return () => {
      stopCountdown();
    };
  }, []);

  return (
    <CountdownContext.Provider
      value={{
        timeLeft: getReturnValues(countDown),
        startCountDown,
        stopCountdown,
        completed: countDown <= 0,
        started,
      }}
    >
      {children}
    </CountdownContext.Provider>
  );
};

export const useCountdown = () => {
  const context = useContext(CountdownContext);
  if (!context) {
    throw new Error('useCountdown must be used within a CountdownProvider');
  }
  return context;
};
