import { useEffect } from 'react';

type Callback = () => void;

export const useNativeGoBack = (
  isNavigating: boolean,
  hasBack: boolean,
  loading: boolean,
  callBack: Callback | undefined,
): void => {
  useEffect(() => {
    window.goBack = () => {
      if (hasBack && !loading && !isNavigating) callBack && callBack();
    };
  }, [isNavigating, hasBack, loading]);
};
