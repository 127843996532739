import { useSendSegmentEvents } from '@talk360/hooks';
import type { Card } from '@talk360/types';
import type { Dispatch, RefObject } from 'react';
import { createContext, useContext, useEffect, useState, useReducer } from 'react';

type ScrollContextProps = {
  activeCarousel: number;
  isLast: boolean;
  isFirst: boolean;
  skipped: boolean;
  visitedSlide: { [key in Card]: boolean };
  scrollCard(): void;
  setSkipped(skip: boolean): void;
  setCards(cards: Card[]): void;
  resetActive(): void;
  setElementRef(element: RefObject<HTMLDivElement> | null): void;
  dispatchVisit: Dispatch<Card>;
};

type VisitedCards = { [key in Card]: boolean };

export const ScrollContext = createContext<ScrollContextProps | null>(null);

export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeCarousel, setActiveCarousel] = useState<number>(0);
  const [elementRef, setElementRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [skipped, setSkipped] = useState<boolean>(false);
  const [cards, setCards] = useState<Card[]>([]);

  const { sendTrack } = useSendSegmentEvents('welcome');

  const visitedCardsReducer = (state: VisitedCards, action: Card) => {
    if (!state[action]) {
      sendTrack(`click_signup_carousel_next_button_${action}`);
      return { ...state, [action]: true };
    }
    return state;
  };

  const [visitedSlide, dispatchVisit] = useReducer(visitedCardsReducer, {} as VisitedCards);

  const totalCarousel = () =>
    Math.round((elementRef?.current?.scrollWidth || 0) / (elementRef?.current?.clientWidth || 0));

  const handleOnScroll = () => {
    if (!elementRef?.current) return;
    const { clientWidth, scrollLeft } = elementRef.current;
    const active = Math.round(scrollLeft / clientWidth);
    setActiveCarousel(active);
    const card = cards[active - 1];
    if (!card) return;
    dispatchVisit(card);
  };

  useEffect(() => {
    if (!elementRef?.current) return;
    const { current } = elementRef;

    current.addEventListener('scroll', handleOnScroll, true);
    return () => {
      current.removeEventListener('scroll', handleOnScroll, true);
    };
  }, [elementRef]);

  const scrollCard = (): void => {
    if (!elementRef?.current) return;
    const { clientWidth, scrollLeft } = elementRef.current;
    elementRef.current.scrollTo({ behavior: 'smooth', left: scrollLeft + clientWidth });
  };

  const resetActive = () => setActiveCarousel(0);

  return (
    <ScrollContext.Provider
      value={{
        activeCarousel,
        isLast: totalCarousel() - 1 === activeCarousel,
        isFirst: activeCarousel === 0,
        skipped,
        visitedSlide,
        scrollCard,
        resetActive,
        setElementRef,
        setSkipped,
        dispatchVisit,
        setCards,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
